/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "button": {},
  "tag": {
    "size_text": "font-primary text-sm leading-tight"
  },
  "empty_size_default": "mt-3 gap-6 col-span-4",
  "empty_colors_default": "",
  "empty_variant_default": "flex flex-col",
  "headline_variant_default": "inline",
  "headline_colors_default": "bg-white",
  "headline_size_default": "pr-3 text-headline",
  "line_variant_default": "border-b",
  "line_colors_default": "border-black",
  "line_size_default": "mb-3 h-4",
  "button_variant_default": "",
  "button_colors_default": "",
  "button_size_default": "mx-auto mt-8 w-fit",
  "icon_variant_default": "cursor-pointer",
  "icon_colors_default": "text-gray-400",
  "icon_size_default": "",
  "article_caption_variant_default": "",
  "article_caption_colors_default": "",
  "article_caption_size_default": "pt-3 text-preamble md:text-headline",
  "article_description_variant_default": "",
  "article_description_colors_default": "",
  "article_description_size_default": "pt-2 text-body-xs md:text-body",
  "article_header_variant_default": "flex justify-between items-center",
  "article_header_colors_default": "",
  "article_header_size_default": "pt-3",
  "article_link_variant_default": "",
  "article_link_colors_default": "",
  "article_link_size_default": "",
  "article_image_variant_default": "",
  "article_image_colors_default": "",
  "article_image_size_default": "",
  "article_variant_default": "",
  "article_colors_default": "",
  "article_size_default": "",
  "variant_default": "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
  "colors_default": "",
  "size_default": "gap-6"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SavedArticles");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
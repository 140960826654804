/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneArticleDisclosureLabel as ResolvedStandaloneArticleDisclosureLabel } from 'base/components/standalone/ArticleDisclosureLabel';

export const ArticleDisclosureLabel = ResolvedStandaloneArticleDisclosureLabel;

export type ArticleDisclosureLabelProps = PropsFromComponent<typeof ArticleDisclosureLabel>;

export const ArticleDisclosureLabelWith = (extras: DynamicStandaloneExtras): typeof ArticleDisclosureLabel => {
    return function ArticleDisclosureLabel(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleDisclosureLabel');
        return <ResolvedStandaloneArticleDisclosureLabel {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { Instagram } from '@/components/Instagram';
import { LabradorComponent } from '@/types/component';

export const LabradorInstagram: LabradorComponent = ({ type, data, meta }) => {
  return (
    <Instagram
      postId={data?.postId}
      options={data.options}
      hidecaption={data.hidecaption}
      data-content-index={meta.contentIndex}
    />
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorBodytext as ResolvedLabradorBodytext } from 'base/components/labrador/Bodytext';

export const LabradorBodytext: typeof ResolvedLabradorBodytext = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorBodytext {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorBodytextProps = PropsFromComponent<typeof LabradorBodytext>;

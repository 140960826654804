/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "headline_colors_default": "",
  "headline_size_default": "text-headline-3xs",
  "headline_variant_default": "uppercase",
  "slider_colors_default": "",
  "slider_size_default": "w-full",
  "slider_variant_default": "",
  "slide_image_colors_default": "group-even/slide:bg-secondary-500 group-odd/slide:bg-primary-700",
  "slide_image_size_default": "w-full mb-1.5",
  "slide_image_variant_default": "rounded-xl sm:rounded-3xl",
  "slide_caption_colors_default": "text-primary-700",
  "slide_caption_size_default": "text-headline-3xs",
  "slide_caption_variant_default": "",
  "slide_description_colors_default": "",
  "slide_description_size_default": "text-headline-2xs sm:text-headline-xs md:text-headline-sm",
  "slide_description_variant_default": "",
  "slide_colors_default": "",
  "slide_size_default": "w-40 sm:w-54 md:w-68 gap-y-1.5",
  "slide_variant_default": "group/slide flex flex-col",
  "colors_default": "",
  "size_default": "gap-y-3.5 mx-auto max-w-full",
  "variant_default": "flex flex-col"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ExpertSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
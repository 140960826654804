import c from '@/styles/Byline';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface BylineAuthorProps extends ComponentProps<'span'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

export const BylineAuthorComponent: Component<BylineAuthorProps> = ({
  className,
  variant,
  colors,
  size,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('author_variant', variant, 'default');
  const colorsClassName = getPropStyles('author_colors', colors, 'default');
  const sizesClassName = getPropStyles('author_size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizesClassName, className);

  return (
    <span className={componentClassName} {...props}>
      {children}
    </span>
  );
};

import { Button } from '@/components/Button';
import { cn } from 'lib/utils';
import { useState } from 'react';

export interface PreProps {
  expanded?: boolean;
  children?: React.ReactNode;
}

export const StandalonePre: React.FC<PreProps> = ({ expanded, children }) => {
  const [showFull, setShowFull] = useState(expanded);

  function toggleShow() {
    setShowFull(showFull ? false : true);
  }

  return (
    <div className="relative my-2 rounded-lg bg-slate-800 p-2">
      <Button
        content={showFull ? 'Hide' : 'Show'}
        options={{ className: '!absolute right-3 top-3', size: 'small', onClick: toggleShow }}
      />
      <pre
        className={cn('font-mono', 'text-xs', 'text-white', 'overflow-auto', {
          'line-clamp-4': !showFull,
        })}
      >
        {children}
      </pre>
    </div>
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorSavedArticles as ResolvedLabradorSavedArticles } from 'base/components/labrador/SavedArticles';

export const LabradorSavedArticles: typeof ResolvedLabradorSavedArticles = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorSavedArticles {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorSavedArticlesProps = PropsFromComponent<typeof LabradorSavedArticles>;

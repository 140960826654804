/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorMostRead as ResolvedLabradorMostRead } from 'base/components/labrador/MostRead';

export const LabradorMostRead: typeof ResolvedLabradorMostRead = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorMostRead {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorMostReadProps = PropsFromComponent<typeof LabradorMostRead>;

import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useId } from 'react';
import { RadioButton, RadioButtonProps } from './RadioButton';
import { RadioButtonInputProps } from './RadioButton.Input';
import { RadioButtonLabelProps } from './RadioButton.Label';

export interface StandaloneRadioButtonProps extends StandaloneComponentProps {
  label?: string;
  name?: RadioButtonInputProps['name'];
  value?: RadioButtonInputProps['value'];
  options?: RadioButtonProps & {
    $input?: RadioButtonInputProps;
    $label?: RadioButtonLabelProps;
  };
}

export const StandaloneRadioButton: StandaloneComponent<StandaloneRadioButtonProps> = ({
  options,
  name,
  value,
  label,
  ...props
}) => {
  const { $input: inputProps, $label: labelProps, variant, ...baseProps } = options ?? {};

  const id = useId();

  return (
    <RadioButton {...baseProps} {...props}>
      <RadioButton.Input id={id} type="radio" {...{ variant, name, value }} {...inputProps} />
      <RadioButton.Label htmlFor={id} {...labelProps}>
        {label}
      </RadioButton.Label>
    </RadioButton>
  );
};

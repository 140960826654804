import { propClassName } from '@/styles/NewsletterSignup';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';

export interface NewsletterSignupRootErrorProps extends ComponentProps<'p'> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export const NewsletterSignupRootErrorComponent: Component<NewsletterSignupRootErrorProps> = ({
  className,
  colors,
  size,
  variant,
  children,
  ...props
}) => {
  const colorsClassName = propClassName('rootError_colors', colors, 'primary');
  const sizeClassName = propClassName('rootError_size', size ?? variant, 'default');
  const variantClassName = propClassName('rootError_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <p className={componentClassName} {...props}>
      {children}
    </p>
  );
};

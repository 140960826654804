import { ImageProps, ImageWith } from '@/components/Image';
import { useStableProps } from '@/hooks/useStable';
import { ClassNameContext, useTheme } from '@/styles/AffiliateItem';
import { StandaloneComponent } from '@/types/component';
import { useContext } from 'react';

const Image = ImageWith({ theme: { useTheme } });

export const AffiliateItemProductImageComponent: StandaloneComponent<ImageProps> = (props) => {
  const { variant } = useContext(ClassNameContext);
  const stableProps = useStableProps({ options: { variant } }, props);

  return <Image {...stableProps} />;
};

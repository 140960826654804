/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorInstagram as ResolvedLabradorInstagram } from 'base/components/labrador/Instagram';

export const LabradorInstagram: typeof ResolvedLabradorInstagram = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorInstagram {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorInstagramProps = PropsFromComponent<typeof LabradorInstagram>;

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorRow as ResolvedLabradorRow } from 'base/components/labrador/Row';

export const LabradorRow: typeof ResolvedLabradorRow = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorRow {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorRowProps = PropsFromComponent<typeof LabradorRow>;

import { propClassName } from '@/styles/Quotebox';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { withNonHTMLChildren, withSafeInnerHTML } from '@/utils/index';
import { cn } from 'lib/utils/cn';
import { useQuoteboxContext } from './Quotebox';

export interface QuoteboxQuoteProps extends ComponentProps<'p'> {
  variant?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp;
  colors?: ClassNameProp;
}

export const QuoteboxQuoteComponent: Component<QuoteboxQuoteProps> = ({
  children,
  className,
  variant,
  colors,
  size,
  ...props
}) => {
  const context = useQuoteboxContext({ variant, size, colors });

  const variantClassName = propClassName('quote_variant', context.variant, 'primary');
  const sizeClassName = propClassName('quote_size', context.size);
  const colorsClassName = propClassName('quote_colors', context.colors);

  const componentClassName = cn(variantClassName, sizeClassName, colorsClassName, className);

  return (
    <p className={componentClassName} {...props} {...withSafeInnerHTML(children)}>
      {withNonHTMLChildren(children)}
    </p>
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneFacebook as ResolvedStandaloneFacebook } from 'base/components/standalone/Facebook';

export const Facebook = ResolvedStandaloneFacebook;

export type FacebookProps = PropsFromComponent<typeof Facebook>;

export const FacebookWith = (extras: DynamicStandaloneExtras): typeof Facebook => {
    return function Facebook(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Facebook');
        return <ResolvedStandaloneFacebook {...mergeProps({ options: { theme } }, props)} />;
    }
}
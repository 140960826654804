import { Link, LinkProps } from '@/components/Link';
import { componentTheme } from '@/styles/SavedArticles';
import { StandaloneComponent } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type SavedArticlesArticleLinkProps = LinkProps;

export const SavedArticlesArticleLinkComponent: StandaloneComponent<SavedArticlesArticleLinkProps> = (props) => {
  return (
    <Link
      {...mergeProps(
        {
          options: {
            theme: componentTheme('link'),
          },
        },
        props,
      )}
    />
  );
};

import { Columns } from '@/components/Columns';
import { LabradorComponent } from '@/types/component';

import { withColumnsSystem } from '@/utils/withGridSystem';
import { renderContentComponent } from 'modules/dynamic/components';
import { Fragment } from 'react';

export const LabradorColumns: LabradorComponent = ({ type, meta, descendants }) => {
  const contentIndex = meta.contentIndex;

  if (!descendants?.length) {
    return null;
  }

  const content = descendants.map((child, index) => {
    return <Fragment key={index}>{renderContentComponent(child)}</Fragment>;
  });

  return (
    <Columns
      options={{ className: withColumnsSystem(meta.width) }}
      data-content-index={contentIndex}
      {...{ content }}
    />
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneJwVideoArticle as ResolvedStandaloneJwVideoArticle } from 'base/components/standalone/JwVideo/Article';

export const JwVideoArticle = ResolvedStandaloneJwVideoArticle;

export type JwVideoArticleProps = PropsFromComponent<typeof JwVideoArticle>;

export const JwVideoArticleWith = (extras: DynamicStandaloneExtras): typeof JwVideoArticle => {
    return function JwVideoArticle(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'JwVideoArticle');
        return <ResolvedStandaloneJwVideoArticle {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { useClassName } from '@/styles/TagsIndex';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { TagsIndexHeadlineComponent } from './TagsIndex.Headline';
import { TagsIndexLetterComponent } from './TagsIndex.Letter';
import { TagsIndexTagComponent } from './TagsIndex.Tag';
import { TagsIndexTagsComponent } from './TagsIndex.Tags';

export type TagsIndexProps = ComponentProps<'div'>;

const TagsIndexComponent: Component<TagsIndexProps> = ({ children, ...props }) => {
  const className = useClassName('', props);
  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

export const TagsIndex = Object.assign(TagsIndexComponent, {
  Headline: TagsIndexHeadlineComponent,
  Letter: TagsIndexLetterComponent,
  Tags: TagsIndexTagsComponent,
  Tag: TagsIndexTagComponent,
});

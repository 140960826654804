/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneScrollPageControls as ResolvedStandaloneScrollPageControls } from 'base/components/standalone/ScrollPageControls';

export const ScrollPageControls = ResolvedStandaloneScrollPageControls;

export type ScrollPageControlsProps = PropsFromComponent<typeof ScrollPageControls>;

export const ScrollPageControlsWith = (extras: DynamicStandaloneExtras): typeof ScrollPageControls => {
    return function ScrollPageControls(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ScrollPageControls');
        return <ResolvedStandaloneScrollPageControls {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { propClassName } from '@/styles/RecommendedArticles';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
export interface RecommendedArticlesHeadlineProps extends ComponentProps<'span'> {
  variant?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
}

export const RecommendedArticlesHeadlineComponent: Component<RecommendedArticlesHeadlineProps> = ({
  variant,
  children,
  size,
  colors,
  className,
  ...props
}) => {
  const variantClassName = propClassName('headline_variant', variant, 'default');
  const sizeClassName = propClassName('headline_size', size, 'default');
  const colorsClassName = propClassName('headline_colors', colors, 'default');

  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <span className={componentClassName} {...props}>
      {children}
    </span>
  );
};

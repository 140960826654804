import { Byline } from '@/components/Byline';
import { LabradorComponent } from '@/types/component';

export const LabradorByline: LabradorComponent = ({ type, data, meta }) => {
  // TODO: Check descendants for an image. If there is one, use that instead of the default one
  const author = [data.firstName, data.lastName].filter(Boolean).join(' ');

  return (
    <Byline
      authorPageSlug={data.authorPageSlug}
      type={data.type}
      author={author}
      image={data.image}
      text={data.text}
      options={data.options}
    />
  );
};

import { useClassName } from '@/styles/ShowsSlider';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { ShowsSliderCaptionComponent } from './ShowsSlider.Caption';
import { ShowsSliderHeaderComponent } from './ShowsSlider.Header';
import { ShowsSliderLinkComponent } from './ShowsSlider.Link';
import { ShowsSliderSlideComponent } from './ShowsSlider.Slide';
import { ShowsSliderTitleComponent } from './ShowsSlider.Title';

export interface ShowsSliderProps extends ComponentProps<'div'> {}

const ShowsSliderComponent: Component<ShowsSliderProps> = ({ children, ...props }) => {
  const className = useClassName('', props);

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

export const ShowsSlider = Object.assign(ShowsSliderComponent, {
  Link: ShowsSliderLinkComponent,
  Caption: ShowsSliderCaptionComponent,
  Header: ShowsSliderHeaderComponent,
  Title: ShowsSliderTitleComponent,
  Slide: ShowsSliderSlideComponent,
});

import { Image, ImageProps } from '@/components/Image';
import { propClassName } from '@/styles/AuthorsList';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from 'lib/utils';
import { useState } from 'react';

const defaultFallback = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`;

export interface AuthorsListItemImageProps extends ExtendedStandaloneComponentProps<ImageProps> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const AuthorsListItemImageComponent: ExtendedStandaloneComponent<AuthorsListItemImageProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const { options, src, fallback, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const [isError, setError] = useState(false);

  const colorsClassName = propClassName('item_image_colors', colors);
  const sizeClassName = propClassName('item_image_size', size);
  const variantClassName = propClassName('item_image_variant', variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  return (
    <Image
      src={isError ? (fallback ?? defaultFallback) : src}
      options={{
        $group: { size: 'none' },
        onError: () => setError(true),
        size: 'none',
        className: componentClassName,
        loader: isError ? () => (fallback as any)?.src ?? defaultFallback : undefined,
        ...standaloneOptions,
      }}
      {...standaloneProps}
      {...props}
    />
  );
};

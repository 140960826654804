/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTextSubtitle as ResolvedStandaloneTextSubtitle } from 'base/components/standalone/TextSubtitle';

export const TextSubtitle = ResolvedStandaloneTextSubtitle;

export type TextSubtitleProps = PropsFromComponent<typeof TextSubtitle>;

export const TextSubtitleWith = (extras: DynamicStandaloneExtras): typeof TextSubtitle => {
    return function TextSubtitle(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TextSubtitle');
        return <ResolvedStandaloneTextSubtitle {...mergeProps({ options: { theme } }, props)} />;
    }
}
/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneEmphasis as ResolvedStandaloneEmphasis } from 'base/components/standalone/Emphasis';

export const Emphasis = ResolvedStandaloneEmphasis;

export type EmphasisProps = PropsFromComponent<typeof Emphasis>;

export const EmphasisWith = (extras: DynamicStandaloneExtras): typeof Emphasis => {
    return function Emphasis(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Emphasis');
        return <ResolvedStandaloneEmphasis {...mergeProps({ options: { theme } }, props)} />;
    }
}
/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "variant_default": "flex items-center after:flex-grow",
  "colors_default": "after:bg-primary-700",
  "size_default": "text-headline-3xs mt-9 mb-3 mx-auto px-4 w-full lg:w-2/3 gap-3 after:h-[10px]"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "NoInfinityTeaserBoxTitle");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
import { componentClassName } from '@/styles/BreakingNews';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { useBreakingNewsContext } from './BreakingNews';

export interface BreakingNewsItemHeadlineProps extends ComponentProps<'h3'> {
  variant?: ClassNameProp<'default' | 'alternative'>;
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
}

export const BreakingNewsItemHeadlineComponent: Component<BreakingNewsItemHeadlineProps> = ({
  variant,
  size,
  colors,
  children,
  ...props
}) => {
  const { theme, ...contextClassNameProps } = useBreakingNewsContext({ colors, size, variant });
  const className = componentClassName('item_headline', contextClassNameProps, { theme });

  return <h3 {...mergeProps({ className }, props)}>{children}</h3>;
};

import c from '@/styles/TextArea';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { TextAreaStatuses } from '.';

export interface TextAreaCaptionGroupProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  status?: ClassNameProp<TextAreaStatuses>;
}

export const TextAreaCaptionGroupComponent: Component<TextAreaCaptionGroupProps> = ({
  className,
  variant,
  status,
  size,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('caption_group_variant', variant, 'default');
  const sizeClassName = getPropStyles('caption_group_size', size, 'default');
  const statusClassName = getPropStyles('caption_group_status', status, 'default');
  const componentClassName = cn(variantClassName, sizeClassName, statusClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

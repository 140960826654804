/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "icon": {
    "variant_default": "z-1 absolute rotate-180 right-2.5 top-1/2 -translate-y-1/2",
    "colors_default": "",
    "size_small": "h-4.5 w-5.5"
  },
  "item_blip_variant_default": "absolute left-4.5 top-5 inline-block rounded-full before:inline-block before:absolute before:rounded-full after:inline-block after:absolute after:rounded-full",
  "item_blip_variant_alternative": "hidden",
  "item_blip_colors_default": "bg-primary-700 before:bg-primary-700 after:bg-primary-700",
  "item_blip_colors_alternative": "",
  "item_blip_size_default": "w-2 h-2 before:w-2 before:h-2 after:w-2 after:h-2",
  "item_blip_size_alternative": "",
  "item_blip_animation_default": "before:animate-blip-inner-circle after:animate-blip-outer-circle",
  "item_headline_variant_default": "inline",
  "item_headline_variant_alternative": "block",
  "item_headline_colors_default": "text-black",
  "item_headline_colors_alternative": "text-black",
  "item_headline_size_default": "text-headline-sm sm:text-headline-md",
  "item_headline_size_alternative": "text-headline-2xs sm:text-headline-3xl mt-1 sm:mt-3",
  "item_label_variant_default": "after:content-[\":\"]",
  "item_label_variant_alternative": "inline-block py-1 sm:py-1.5 px-1.5 sm:px-2.5 uppercase",
  "item_label_colors_default": "text-primary-700",
  "item_label_colors_alternative": "text-white bg-primary-700",
  "item_label_size_default": "mr-1 text-headline-sm sm:text-headline-md",
  "item_label_size_alternative": "text-headline-3xs sm:text-headline-2xs",
  "item_variant_default": "",
  "item_variant_alternative": "[&_svg]:hidden",
  "item_colors_default": "bg-gray-100",
  "item_colors_alternative": "bg-gray-100",
  "item_size_default": "py-2.5 px-9",
  "item_size_alternative": "p-3 sm:p-6",
  "variant_default": "",
  "variant_alternative": "",
  "colors_default": "",
  "colors_alternative": "",
  "size_default": "mb-3 mt-1",
  "size_alternative": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "BreakingNews");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
import { propClassName } from '@/styles/NewsletterSignup';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';
import { cn } from 'lib/utils/cn';

export interface NewsletterSignupFooterProps extends ComponentProps<'footer'> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export const NewsletterSignupFooterComponent: Component<NewsletterSignupFooterProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('footer_colors', colors, 'primary');
  const sizeClassName = propClassName('footer_size', size ?? variant, 'default');
  const variantClassName = propClassName('footer_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <footer className={componentClassName} {...withSafeInnerHTML(children)} {...props}>
      {withNonHTMLChildren(children)}
    </footer>
  );
};

import { useClassName } from '@/styles/TagsIndex';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type TagsIndexHeadlineProps = ComponentProps<'h2'>;

export const TagsIndexHeadlineComponent: Component<TagsIndexHeadlineProps> = ({ children, ...props }) => {
  const className = useClassName('headline', props);

  return <h2 {...mergeProps({ className }, props)}>{children}</h2>;
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorList as ResolvedLabradorList } from 'base/components/labrador/List';

export const LabradorList: typeof ResolvedLabradorList = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorList {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorListProps = PropsFromComponent<typeof LabradorList>;

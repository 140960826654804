import { ShowsSlider } from '@/components/ShowsSlider';
import { http } from '@/utils/http';
import { getSite, slugify } from '@/utils/index';
import { StandaloneArticleFooter } from 'base/components/ArticleFooter';
import { videoProxyUrl } from 'base/components/JwVideo/constants';
import type { Show } from 'base/components/ShowsSlider';
import mergeProps from 'lib/utils/mergeProps';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

interface ShowPlaylist {
  description: string;
  horizontalImage: string;
  playlistCategory: string;
  playlistId: string;
  title: string;
  verticalImage: string;
}

export const SvenskdamStandaloneArticleFooter: typeof StandaloneArticleFooter = (props) => {
  const [shows, setShows] = useState<Show[]>([]);

  const fetchShows = async () => {
    const brands = getSite();
    const url = `${videoProxyUrl}/api/playlistByCategory/${brands}/show`;
    const response = await http<{ data: { playlistByCategory: ShowPlaylist[] } }>(url);
    const data = response?.data?.data?.playlistByCategory ?? [];
    if (data.length) {
      const showsData: Show[] = data.slice(0, 3).map((show) => ({
        description: show.description,
        src: show.verticalImage || show.horizontalImage,
        url: `/video/show/${slugify(show.title)}/${show.playlistId}`,
        playlistId: show.playlistId,
        playlistCategory: show.playlistCategory,
      }));
      setShows(showsData);
    }
  };

  useEffectOnce(() => {
    fetchShows();
  });

  return (
    <>
      <StandaloneArticleFooter
        {...mergeProps(
          {
            options: {
              $tag: {
                options: {
                  size: 'small',
                },
              },
            },
          },
          props,
        )}
      />
      <ShowsSlider shows={shows} />
    </>
  );
};

import { propClassName } from '@/styles/NewsletterSignup';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';

export interface NewsletterSignupHeadlineProps extends ComponentProps<'h2'> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export const NewsletterSignupHeadlineComponent: Component<NewsletterSignupHeadlineProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('headline_colors', colors, 'primary');
  const sizeClassName = propClassName('headline_size', size ?? variant, 'default');
  const variantClassName = propClassName('headline_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <h2 className={componentClassName} {...props}>
      {children}
    </h2>
  );
};

import { Checkbox, CheckboxProps } from '@/components/Checkbox';
import { propClassName } from '@/styles/NewsletterSignup';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { RegisterOptions } from 'react-hook-form';
import { useNewsletterSignupState } from './NewsletterSignup';

export interface NewsletterSignupInputCheckboxProps extends ExtendedStandaloneComponentProps<CheckboxProps> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  rules?: RegisterOptions<any>;
}

export const NewsletterSignupInputCheckboxComponent: ExtendedStandaloneComponent<
  NewsletterSignupInputCheckboxProps
> = ({ $standalone, className, colors, rules, size, variant, ...props }) => {
  const { register } = useNewsletterSignupState();

  const { name, options, ...standaloneProps } = $standalone ?? {};
  const { $input, $label, className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const { className: inputClassName, ...inputProps } = $input ?? {};
  const { className: labelClassName, ...labelProps } = $label ?? {};

  const inputColorsClassName = propClassName('inputCheckbox_input_colors', colors, 'primary');
  const inputSizeClassName = propClassName('inputCheckbox_input_size', size, 'default');
  const inputVariantClassName = propClassName('inputCheckbox_input_variant', variant, 'default');
  const inputComponentClassName = cn(inputColorsClassName, inputSizeClassName, inputVariantClassName, inputClassName);

  const labelColorsClassName = propClassName('inputCheckbox_label_colors', colors, 'primary');
  const labelSizeClassName = propClassName('inputCheckbox_label_size', size, 'default');
  const labelVariantClassName = propClassName('inputCheckbox_label_variant', variant, 'default');
  const labelComponentClassName = cn(labelColorsClassName, labelSizeClassName, labelVariantClassName, labelClassName);

  const colorsClassName = propClassName('inputCheckbox_colors', colors, 'primary');
  const sizeClassName = propClassName('inputCheckbox_size', size, 'default');
  const variantClassName = propClassName('inputCheckbox_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  const registration = name ? register(name, rules) : {};

  return (
    <Checkbox
      options={{
        $input: {
          className: inputComponentClassName,
          ...inputProps,
          ...registration,
        },
        $label: {
          className: labelComponentClassName,
          ...labelProps,
        },
        className: componentClassName,
        ...standaloneOptions,
      }}
      data-field={name}
      {...{ name }}
      {...standaloneProps}
      {...props}
    />
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneArticle as ResolvedStandaloneArticle } from 'base/components/standalone/Article';

export const Article = ResolvedStandaloneArticle;

export type ArticleProps = PropsFromComponent<typeof Article>;

export const ArticleWith = (extras: DynamicStandaloneExtras): typeof Article => {
    return function Article(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Article');
        return <ResolvedStandaloneArticle {...mergeProps({ options: { theme } }, props)} />;
    }
}
import c from '@/styles/JwVideoShoppable';
import { Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import Script from 'next/script';

export interface VideoShoppableProps extends ComponentProps<'div'> {
  mediaId: string;
}

const VideoShoppable: Component<VideoShoppableProps> = ({ mediaId, variant, className, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('variant', variant, 'default');

  const componentClassName = cn(variantClassName, className);

  return (
    <>
      <div className={componentClassName} data-mediaid={mediaId} {...props} />
      <Script async type="text/javascript" src="https://aller-live-shopping.vercel.app/scripts/embed/bundle.js" />
    </>
  );
};

export const ShoppableVideo = Object.assign(VideoShoppable, {});

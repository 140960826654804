/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneByline as ResolvedStandaloneByline } from 'base/components/standalone/Byline';

export const Byline = ResolvedStandaloneByline;

export type BylineProps = PropsFromComponent<typeof Byline>;

export const BylineWith = (extras: DynamicStandaloneExtras): typeof Byline => {
    return function Byline(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Byline');
        return <ResolvedStandaloneByline {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { Icon, IconProps } from '@/components/Icon';
import { componentTheme } from '@/styles/BreakingNews';
import { StandaloneComponent } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type BreakingNewsItemIconProps = IconProps;

export const BreakingNewsItemIconComponent: StandaloneComponent<BreakingNewsItemIconProps> = (props) => {
  return (
    <Icon
      {...mergeProps(
        {
          options: {
            theme: componentTheme('icon'),
          },
          name: 'sliderArrow',
        },
        props,
      )}
    />
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandalonePageMeta as ResolvedStandalonePageMeta } from 'base/components/standalone/PageMeta';

export const PageMeta = ResolvedStandalonePageMeta;

export type PageMetaProps = PropsFromComponent<typeof PageMeta>;

export const PageMetaWith = (extras: DynamicStandaloneExtras): typeof PageMeta => {
    return function PageMeta(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'PageMeta');
        return <ResolvedStandalonePageMeta {...mergeProps({ options: { theme } }, props)} />;
    }
}
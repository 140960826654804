import { AccordionTheme } from '@/components/Accordion/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: AccordionTheme });

const Base = $({ as: 'div', slot: 'base' });
const Toggle = $({ as: 'div', slot: 'toggle' });
const Group = $({ as: 'div', slot: 'group' });
const Content = $({ as: 'div', slot: 'content' });
const Headline = $({ as: 'span', slot: 'headline' });

export const Accordion = Object.assign(Base, {
  Toggle,
  Group,
  Content,
  Headline,
});

import c from '@/styles/ArticleTeaserBodytext';
import type { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { ArticleTeaserBodytextVariant, DefaultArticleTeaserBodytextVariant } from './ArticleTeaserBodytext';

export interface ArticleTeaserBodytextDescriptionProps extends ComponentProps<'span'> {
  variant?: ClassNameProp<ArticleTeaserBodytextVariant>;
  colors?: ClassNameProp<ArticleTeaserBodytextVariant>;
  size?: ClassNameProp<'default'>;
}

export const ArticleTeaserBodytextDescription: Component<ArticleTeaserBodytextDescriptionProps> = ({
  className,
  colors,
  size,
  variant,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('description_variant', variant, DefaultArticleTeaserBodytextVariant);
  const colorsClassName = getPropStyles('description_colors', colors ?? variant, DefaultArticleTeaserBodytextVariant);
  const sizeClassName = getPropStyles('description_size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <span className={componentClassName} {...withSafeInnerHTML(children)} {...props}>
      {withNonHTMLChildren(children)}
    </span>
  );
};

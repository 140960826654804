import c from '@/styles/TextArea';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { TextAreaCaptionComponent } from './TextArea.Caption';
import { TextAreaCaptionGroupComponent } from './TextArea.Caption.Group';
import { TextAreaFieldComponent } from './TextArea.Field';
import { TextAreaFieldGroupComponent } from './TextArea.Field.Group';
import { TextAreaLabelComponent } from './TextArea.Label';

export interface TextAreaProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
}

const TextAreaComponent: Component<TextAreaProps> = ({ children, className, variant, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('variant', variant, 'default');
  const componentClassName = cn(variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const TextArea = Object.assign(TextAreaComponent, {
  Label: TextAreaLabelComponent,
  Field: Object.assign(TextAreaFieldComponent, {
    Group: TextAreaFieldGroupComponent,
  }),
  Caption: Object.assign(TextAreaCaptionComponent, {
    Group: TextAreaCaptionGroupComponent,
  }),
});

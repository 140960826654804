import { Icon, IconProps } from '@/components/Icon';
import { propClassName } from '@/styles/ArticleSlider';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { ArticleSliderDefaultSize, ArticleSliderSizes } from '.';

export interface ArticleSliderArrowProps extends ExtendedStandaloneComponentProps<IconProps> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<ArticleSliderSizes>;
  variant?: ClassNameProp<'left' | 'right'>;
}

export const ArticleSliderArrowComponent: ExtendedStandaloneComponent<ArticleSliderArrowProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const { options, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const colorsClassName = propClassName('arrow_colors', colors, 'primary');
  const sizeClassName = propClassName('arrow_size', size, ArticleSliderDefaultSize);
  const variantClassName = propClassName('arrow_variant', variant, 'right');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  return (
    <Icon
      options={{ size: 'extraLarge', className: componentClassName, ...standaloneOptions }}
      {...standaloneProps}
      {...props}
    />
  );
};

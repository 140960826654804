import { useClassName } from '@/styles/ArticleDisclosureLabel';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import useWindowScrollDirection from 'lib/hooks/useWindowScrollDirection';
import { withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';
import mergeProps from 'lib/utils/mergeProps';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { isEmpty, isUndefined, or } from 'typesafe-utils';

export interface ArticleDisclosureLabelProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'primary'>;
}

const ArticleDisclosureLabelComponent: Component<ArticleDisclosureLabelProps> = ({ children, ...props }) => {
  const [topOffset, setTopOffset] = useState<number>(0);
  const scrollDirection = useWindowScrollDirection({ initialDirection: 'up' });
  const className = useClassName('', props, { defaults: { colors: 'primary' } });

  useEffectOnce(() => {
    const menu = document.getElementById('menu');

    if (!menu) return;

    const resizeObserver = new ResizeObserver((entries) => {
      setTopOffset(entries?.[0]?.contentRect?.height ?? 0);
    });

    resizeObserver.observe(menu);

    return () => {
      resizeObserver.unobserve(menu);
    };
  });

  if (or(isUndefined, isEmpty)(children)) {
    return null;
  }

  return (
    <div
      {...withSafeInnerHTML(children)}
      {...mergeProps(
        {
          style: { top: topOffset },
          'data-scroll-direction': scrollDirection,
          className,
        },
        props
      )}
    >
      {withNonHTMLChildren(children)}
    </div>
  );
};

export const ArticleDisclosureLabel = ArticleDisclosureLabelComponent;

/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "slide": {
    "caption_colors_default": "text-white"
  },
  "swiper": {
    "arrow_colors_default": "text-primary-500",
    "navigation_item_colors_default": "bg-primary-600 data-[active=true]:bg-primary-200",
    "title_colors_default": "text-white before:bg-primary-700",
    "title_size_default": "text-headline-sm md:text-headline pb-3 md:pb-6 ml-6 md:ml-0 before:w-full before:h-px",
    "title_variant_default": "md:text-center overflow-hidden before:block before:relative before:top-3 before:left-28 md:before:hidden"
  },
  "colors_default": "bg-primary-900 wings-primary-900",
  "size_default": "pb-4 md:pb-6 pt-3 md:pt-6 md:px-3 w-screen -ml-[calc(50vw-50%)] md:w-full md:ml-0",
  "variant_default": "relative wings"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "BlogSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
import { Markup, MarkupProps } from '@/components/Markup';
import { propClassName } from '@/styles/Tiktok';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';

export interface TiktokProps extends ComponentProps<'section'> {
  colors?: ClassNameProp;
  markup?: MarkupProps['markup'];
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

const TiktokComponent: Component<TiktokProps> = ({ className, colors, markup, size, variant, ...props }) => {
  const colorsClassName = propClassName('colors', colors);
  const sizeClassName = propClassName('size', size);
  const variantClassName = propClassName('variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return <Markup options={{ className: componentClassName }} {...{ markup }} {...props} />;
};

export const Tiktok = Object.assign(TiktokComponent, {});

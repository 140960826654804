/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneParagraph as ResolvedStandaloneParagraph } from 'base/components/standalone/Paragraph';

export const Paragraph = ResolvedStandaloneParagraph;

export type ParagraphProps = PropsFromComponent<typeof Paragraph>;

export const ParagraphWith = (extras: DynamicStandaloneExtras): typeof Paragraph => {
    return function Paragraph(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Paragraph');
        return <ResolvedStandaloneParagraph {...mergeProps({ options: { theme } }, props)} />;
    }
}
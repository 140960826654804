import { propClassName } from '@/styles/Quotebox';
import { ClassNameProp, ClassNameProps, Component, ComponentProps } from '@/types/component';
import { ComponentContextFactory } from 'lib/contexts/ComponentContextFactory';
import { cn } from 'lib/utils/cn';
import { QuoteboxAuthorComponent } from './Quotebox.Author';
import { QuoteboxQuotationMarkComponent } from './Quotebox.QuotationMark';
import { QuoteboxQuoteComponent } from './Quotebox.Quote';

export interface QuoteBoxProps extends ComponentProps<'section'> {
  variant?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp;
  colors?: ClassNameProp;
}

export const {
  Context: QuoteboxContext,
  Provider: QuoteboxProvider,
  useComponentContext: useQuoteboxContext,
} = ComponentContextFactory<Pick<QuoteBoxProps, keyof ClassNameProps>>();

const QuoteBoxComponent: Component<QuoteBoxProps> = ({ children, className, variant, size, colors, ...props }) => {
  const variantClassName = propClassName('variant', variant, 'primary');
  const sizeClassName = propClassName('size', size);
  const colorsClassName = propClassName('colors', colors);

  const componentClassName = cn(variantClassName, sizeClassName, colorsClassName, className);

  return (
    <QuoteboxProvider value={{ colors, size, variant }}>
      <section className={componentClassName} {...props}>
        {children}
      </section>
    </QuoteboxProvider>
  );
};

export const QuoteBox = Object.assign(QuoteBoxComponent, {
  Author: QuoteboxAuthorComponent,
  Quote: QuoteboxQuoteComponent,
  QuotationMark: QuoteboxQuotationMarkComponent,
});

import { useClassName } from '@/styles/JwVideoArticle';

import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export interface VideoArticleExitLinkProps extends ComponentProps<'a'> {
  commercialLink: string;
}

export const VideoArticleExitLinkComponent: Component<VideoArticleExitLinkProps> = ({
  commercialLink,
  children,
  ...props
}) => {
  const className = useClassName('exit_link', props, { className: 'cts-impression-item' });
  return (
    <a
      target="_blank"
      rel="noopener nofollow"
      href={commercialLink}
      data-cts-label="exitLinkButton"
      data-cts-name="exitLinkButton"
      data-cts-id="exitLinkButton"
      data-cts-creative="exitLinkButton"
      {...mergeProps({ className }, props)}
    >
      {children}
    </a>
  );
};

import { Paragraph } from '@/components/Paragraph';
import { LabradorComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import parseStyle from '@/utils/parseStyle';
import { isString } from 'typesafe-utils';

export const LabradorParagraph: LabradorComponent = ({ type, meta, data, descendants, ...props }) => {
  const attributes = { ...meta.attributes };

  attributes.className = attributes.class;
  delete attributes.class;

  if (isString(attributes.style)) {
    attributes.style = parseStyle(attributes.style);
  }

  return <Paragraph content={data.content} {...mergeProps(attributes, props)} />;
};

import { propClassName } from '@/styles/AuthorsList';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn, withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';

export interface AuthorsListHeadlineProps extends ComponentProps<'h1'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const AuthorsListHeadlineComponent: Component<AuthorsListHeadlineProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('headline_colors', colors);
  const sizeClassName = propClassName('headline_size', size);
  const variantClassName = propClassName('headline_variant', variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <h1 className={componentClassName} {...withSafeInnerHTML(children)} {...props}>
      {withNonHTMLChildren(children)}
    </h1>
  );
};

import { ClassNameProvider } from '@/styles/Timer';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { CountdownTime, getCountdownTime } from '@/utils/getCountdownTime';
import { ReactNode, useEffect, useState } from 'react';
import { Timer } from './Timer';

export interface StandaloneTimerProps extends StandaloneComponentProps<typeof Timer> {
  title?: ReactNode;
  finishContent?: ReactNode;
  finishTime?: string;
}

const durationsLabels = ['Dagar', 'Timmar', 'Minuter', 'Sekunder'];

export const StandaloneTimer: StandaloneComponent<StandaloneTimerProps> = ({
  title,
  finishTime,
  finishContent,
  options,
  ...props
}) => {
  const [leftTime, setLeftTime] = useState<CountdownTime>(getCountdownTime({ finishTime, setNegativeAsZero: true }));

  const { $title, $group, $duration: durationProps, ...$base } = options ?? {};
  const { $label, ...$duration } = durationProps ?? {};

  useEffect(() => {
    if (leftTime.totalTime <= 0) return;

    const intervalId = setInterval(() => {
      const time = getCountdownTime({ finishTime });
      setLeftTime(time);

      if (time.totalTime <= 1000 || !time.totalTime) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [finishTime]);

  return (
    <ClassNameProvider value={$base}>
      <Timer {...$base} {...props}>
        {finishContent && (leftTime?.totalTime ?? 0) <= 0 ? (
          <Timer.Finished>{finishContent}</Timer.Finished>
        ) : (
          <>
            <Timer.Title {...$title}>{title}</Timer.Title>
            <Timer.Group {...$group}>
              {durationsLabels.map((duration, durationIndex) => (
                <Timer.Duration key={duration} {...$duration} suppressHydrationWarning>
                  {leftTime?.durations[durationIndex]}
                  <Timer.Duration.Label {...$label}>{duration}</Timer.Duration.Label>
                </Timer.Duration>
              ))}
            </Timer.Group>
          </>
        )}
      </Timer>
    </ClassNameProvider>
  );
};

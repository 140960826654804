import c from '@/styles/TextArea';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { TextAreaStatuses, TextAreaVariants } from '.';

export interface TextAreaLabelProps extends ComponentProps<'label'> {
  colors?: ClassNameProp<'primary' | 'floating'>;
  variant?: ClassNameProp<TextAreaVariants>;
  status?: ClassNameProp<TextAreaStatuses>;
}

export const TextAreaLabelComponent: Component<TextAreaLabelProps> = ({
  className,
  colors,
  variant,
  status,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('label_colors', colors ?? variant, 'primary');
  const variantClassName = getPropStyles('label_variant', variant, 'default');
  const statusClassName = getPropStyles('label_status', status, 'default');
  const componentClassName = cn(colorsClassName, variantClassName, statusClassName, className);

  return (
    <label className={componentClassName} {...props}>
      {children}
    </label>
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorVideoReels as ResolvedLabradorVideoReels } from 'base/components/labrador/VideoReels';

export const LabradorVideoReels: typeof ResolvedLabradorVideoReels = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorVideoReels {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorVideoReelsProps = PropsFromComponent<typeof LabradorVideoReels>;

import { ArticleTeaserDefault } from '@/components/ArticleTeaser/Default';
import { useAppState } from '@/hooks/useAppState';
import { LabradorComponent } from '@/types/component';

import { getCategoryUrl } from 'base/components/ArticleTeaser/Default';

export const LabradorArticleTeaserDefault: LabradorComponent = ({ type, data, meta, descendants }) => {
  const [{ device }] = useAppState();

  const { category, subtitle, kicker, title, publishedUrl, displayProminentTagOnTeaser } = data ?? {};

  const labradorImage = descendants?.find((descendant) => descendant.type === 'labradorImage')?.data;

  const { imageUrl } = labradorImage ?? {};

  const { showKicker, isNativeAd, nativeAdLabel, teaserIndex, showSubtitle, showCategory } = meta ?? {};

  const src = imageUrl?.[device];

  const showKickerOnDevice = showKicker?.[device];
  const showSubtitleOnDevice = showSubtitle?.[device];
  const showCategoryOnDevice = showCategory?.[device];

  const isPriority = teaserIndex < 4;

  const prominentTag = displayProminentTagOnTeaser ? data.prominentTag : undefined;

  const displayCategory = isNativeAd ? nativeAdLabel || category : category;

  return (
    <ArticleTeaserDefault
      caption={showCategoryOnDevice && (prominentTag || displayCategory)}
      description={showSubtitleOnDevice && subtitle}
      kicker={showKickerOnDevice && kicker}
      headline={title}
      links={{
        article: { href: publishedUrl },
        caption: { href: getCategoryUrl(data, meta) },
      }}
      image={{
        src,
        options: { priority: isPriority },
      }}
      options={data.options}
    />
  );
};

/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "tag": {
    "size_text": "text-body-sm",
    "colors_text": "text-black hover:underline"
  },
  "headline_variant_default": "border-b",
  "headline_colors_default": "border-b-gray-300",
  "headline_size_default": "pb-3 mb-8 text-headline-lg md:text-headline-2xl",
  "letter_variant_default": "mb-1",
  "letter_colors_default": "text-primary-700",
  "letter_size_default": "text-headline-sm sm:text-headline-md",
  "tags_variant_default": "flex flex-wrap",
  "tags_colors_default": "",
  "tags_size_default": "mb-5 gap-x-6 gap-y-3",
  "variant_default": "",
  "colors_default": "",
  "size_default": "w-full"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "TagsIndex");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
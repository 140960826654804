import c from '@/styles/Columns';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface ColumnsProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

const ColumnsComponent: Component<ColumnsProps> = ({ children, className, colors, size, variant, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorClassName = getPropStyles('colors', colors, 'default');
  const sizeClassName = getPropStyles('size', size, 'default');
  const variantClassName = getPropStyles('variant', variant, 'default');
  const componentClassName = cn(colorClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const Columns = ColumnsComponent;

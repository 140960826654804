import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export interface VideoArticleVideoContainerNextVideoProps extends ComponentProps<'div'> {}

export const VideoArticleVideoContainerNextVideoComponent: Component<VideoArticleVideoContainerNextVideoProps> = ({
  colors,

  children,
  ...props
}: VideoArticleVideoContainerNextVideoProps) => {
  const className = useClassName('videoContainer_nextVideo', props);
  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Columns, ColumnsProps } from './Columns';

export interface StandaloneColumnsProps extends StandaloneComponentProps {
  content?: ReactNode;
  options?: ColumnsProps;
}

export const StandaloneColumns: StandaloneComponent<StandaloneColumnsProps> = ({ content, options, ...props }) => {
  const { ...baseProps } = options ?? {};

  return (
    <Columns {...baseProps} {...props}>
      {content}
    </Columns>
  );
};

import { CoverBox } from '@/components/CoverBox';
import { LabradorComponent } from '@/types/component';
import { withBackgroundColor } from '@/utils/withBackgroundColor';
import { convertLinkFormat } from 'base/components/CoverBox/convertLinkFormat';
import { renderContentComponent } from 'modules/dynamic/components';

export const LabradorCoverBox: LabradorComponent = ({ type, meta, data, descendants, ...props }) => {
  return (
    <CoverBox
      headline={data.title}
      markup={convertLinkFormat(data.bodytext)}
      images={descendants.map(renderContentComponent)}
      data-bg-color={withBackgroundColor(meta.backgroundColor)}
      data-content-index={meta.contentIndex}
      {...props}
    />
  );
};

import c from '@/styles/InstagramArticlesList';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface InstagramArticlesListDescriptionProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<'default'>;
}

export const InstagramArticlesListDescriptionComponent: Component<InstagramArticlesListDescriptionProps> = ({
  className,
  variant,
  colors,
  size,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('description_variant', variant, 'default');
  const colorClassName = getPropStyles('description_colors', colors, 'primary');
  const sizeClassName = getPropStyles('description_size', size, 'default');

  const componentClassName = cn(variantClassName, colorClassName, sizeClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

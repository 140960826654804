import { propClassName } from '@/styles/NewsletterSignup';
import { ClassNameProp, ComponentProps, ComponentWithRef } from '@/types/component';
import { cn } from 'lib/utils/cn';
import React from 'react';

export interface NewsletterSignupModalProps extends ComponentProps<'dialog'> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export const NewsletterSignupModalComponent: ComponentWithRef<HTMLDialogElement, NewsletterSignupModalProps> =
  React.forwardRef(({ children, className, colors, size, variant, ...props }, ref) => {
    const colorsClassName = propClassName('modal_colors', colors, 'primary');
    const sizeClassName = propClassName('modal_size', size, 'default');
    const variantClassName = propClassName('modal_variant', variant, 'default');
    const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

    return (
      <dialog className={componentClassName} {...{ ref }} {...props}>
        {children}
      </dialog>
    );
  });

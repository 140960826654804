import { useClassName } from '@/styles/Article';
import { Component, ComponentProps } from '@/types/component';
import { Content } from '@/types/content';
import mergeProps from 'lib/utils/mergeProps';
import { renderBySelector } from 'modules/dynamic/components';

export interface ArticleBodytextProps extends ComponentProps<'div'> {
  descendants?: Content[];
}

export const ArticleBodytextComponent: Component<ArticleBodytextProps> = ({ children, descendants = [], ...props }) => {
  const className = useClassName('bodytext', props);

  return (
    <div {...mergeProps({ className }, props)}>
      {renderBySelector('lab-dz-bodytxt', descendants)}
      {children}
    </div>
  );
};

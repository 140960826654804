import { useClassName } from '@/styles/LoopAround';
import { ComponentProps, ComponentWithRef } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { forwardRef } from 'react';

export interface LoopAroundReelProps extends ComponentProps<'div'> {}

export const LoopAroundReelComponent: ComponentWithRef<HTMLDivElement, LoopAroundReelProps> = forwardRef(
  ({ children, ...props }, ref) => {
    const className = useClassName('reel', props);

    return (
      <div {...mergeProps({ className }, props)} ref={ref}>
        {children}
      </div>
    );
  }
);

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorArticle as ResolvedLabradorArticle } from 'base/components/labrador/Article';

export const LabradorArticle: typeof ResolvedLabradorArticle = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorArticle {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorArticleProps = PropsFromComponent<typeof LabradorArticle>;

import { useClassName } from '@/styles/Strossle';
import { StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { useAppState } from 'lib/hooks/useAppState';
import getConfig from 'modules/config';
import Script from 'next/script';
import { useId } from 'react';

export const StandaloneStrossle: StandaloneComponent = (props) => {
  const className = useClassName('', props);
  const [{ affiliate }] = useAppState();
  const id = useId().replace(/:/g, '');

  const strossleConfig = getConfig('strossle') || {};

  const strossleEnabled = strossleConfig.enabled || false;
  const strossleId = strossleConfig.id || '';

  if (!strossleEnabled || !strossleId) return null;

  return (
    <div data-affiliate={affiliate} {...mergeProps({ className }, props)}>
      <Script
        id="strossle-v2-widget-sdk"
        src="https://assets.strossle.com/strossle-widget-sdk/1/strossle-widget-sdk.js"
      ></Script>
      <div data-widgetid={strossleId} id={`strossle-widget-${id}`}></div>
      <Script id={`load-strossle-${id}`}>
        {`window.strossle=window.strossle||function(){(strossle.q=strossle.q||[]).push(arguments)};
        strossle('${strossleId}', '#strossle-widget-${id}');`}
      </Script>
    </div>
  );
};

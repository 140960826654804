import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Tiktok, TiktokProps } from './Tiktok';

export interface StandaloneTiktokProps extends StandaloneComponentProps {
  markup?: TiktokProps['markup'];
  options?: Omit<TiktokProps, 'markup'>;
}

export const StandaloneTiktok: StandaloneComponent<StandaloneTiktokProps> = ({ markup, options, ...props }) => {
  return <Tiktok {...{ markup }} {...options} {...props} />;
};

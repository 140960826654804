import { useClassName } from '@/styles/NoInfinityTeaserBoxTitle';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export interface NoInfinityTeaserBoxTitleProps extends ComponentProps<'h3'> {}

const NoInfinityTeaserBoxTitleComponent: Component<NoInfinityTeaserBoxTitleProps> = ({ children, ...props }) => {
  const className = useClassName('', props);

  return <h3 {...mergeProps({ className }, props)}>{children}</h3>;
};

export const NoInfinityTeaserBoxTitle = NoInfinityTeaserBoxTitleComponent;

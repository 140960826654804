/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneBlogSlider as ResolvedStandaloneBlogSlider } from 'base/components/standalone/BlogSlider';

export const BlogSlider = ResolvedStandaloneBlogSlider;

export type BlogSliderProps = PropsFromComponent<typeof BlogSlider>;

export const BlogSliderWith = (extras: DynamicStandaloneExtras): typeof BlogSlider => {
    return function BlogSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'BlogSlider');
        return <ResolvedStandaloneBlogSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}
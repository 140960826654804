import c from '@/styles/TextArea';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { TextAreaStatuses, TextAreaVariants } from '.';

export interface TextAreaFieldProps extends ComponentProps<'textarea'> {
  variant?: ClassNameProp<TextAreaVariants>;
  size?: ClassNameProp<'default'>;
  status?: ClassNameProp<TextAreaStatuses>;
}

export const TextAreaFieldComponent: Component<TextAreaFieldProps> = ({
  className,
  variant,
  status,
  size,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const statusClassName = getPropStyles('field_status', status, 'default');
  const sizeClassName = getPropStyles('field_size', size, 'default');
  const variantClassName = getPropStyles('field_variant', variant, 'default');
  const componentClassName = cn(statusClassName, variantClassName, sizeClassName, className);

  return <textarea className={componentClassName} {...props} />;
};

import { Box } from '@/components/Box';
import { LabradorComponent } from '@/types/component';
import cn from '@/utils/cn';
import { getChildrenCount } from '@/utils/getChildrenCount';
import { getViewportVisibilityClass } from '@/utils/index';
import { withBackgroundColor } from '@/utils/withBackgroundColor';

import { withColumnsSystem } from '@/utils/withGridSystem';
import { renderContentComponent } from 'modules/dynamic/components';
import { Fragment } from 'react';

export const LabradorBox: LabradorComponent = ({ type, meta, descendants }) => {
  if (!getChildrenCount(descendants)) {
    return null;
  }

  const { contentIndex, backgroundColor, width, titlePosition } = meta;

  const bgColor = withBackgroundColor(backgroundColor);
  const columns = withColumnsSystem(width);
  const viewportVisibilityClass = getViewportVisibilityClass({
    viewportVisibility: meta.viewportVisibility,
    hideViewport: meta.hideViewport,
    display: 'grid',
  });

  const content = descendants.map((child, index) => {
    return <Fragment key={index}>{renderContentComponent(child)}</Fragment>;
  });

  return (
    <Box
      options={{ className: cn(bgColor, columns, viewportVisibilityClass, 'lab') }}
      data-content-index={contentIndex}
      data-title-position={titlePosition}
      {...{ content }}
    />
  );
};

import c from '@/styles/Byline';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { BylineAuthorComponent } from './Byline.Author';
import { BylineImageComponent } from './Byline.Image';
import { BylineTextComponent } from './Byline.Text';

export interface BylineProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

const BylineComponent: Component<BylineProps> = ({ className, variant, colors, size, children, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('variant', variant, 'default');
  const colorsClassName = getPropStyles('colors', colors, 'default');
  const sizeClassName = getPropStyles('size', size, 'default');
  const componentClassName = cn('flex', 'items-center', variantClassName, colorsClassName, sizeClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const Byline = Object.assign(BylineComponent, {
  Author: BylineAuthorComponent,
  Image: BylineImageComponent,
  Text: BylineTextComponent,
});

import { GenericComponentFactory } from 'base/components/GenericComponent';
import { useClassName } from '@/styles/Timer';

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'div', styles: { key: '' } });
const Title = GenericComponent({ as: 'h4', styles: { key: 'title' } });
const Group = GenericComponent({ as: 'div', styles: { key: 'group' } });
const Duration = GenericComponent({ as: 'div', styles: { key: 'duration' } });
const DurationLabel = GenericComponent({ as: 'span', styles: { key: 'duration_label' } });
const Finished = GenericComponent({ as: 'h4', styles: { key: 'finished' } });

export const Timer = Object.assign(Base, {
  Title,
  Finished,
  Group,
  Duration: Object.assign(Duration, {
    Label: DurationLabel,
  }),
});

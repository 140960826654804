import c from '@/styles/Instagram';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { http } from '@/utils/http';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import logger from 'lib/utils/logger';
import getConfig from 'modules/config';
import { useEffect, useState } from 'react';

const helperServicesUrl = getConfig('helperServices.url') || '';
const baseUrl = `${helperServicesUrl}/instagram/embed`;

export interface InstagramProps extends ComponentProps<'div'> {
  postId: string;
  variant?: ClassNameProp<'default'>;
  hidecaption?: boolean;
}

export const Instagram: Component<InstagramProps> = ({ postId, variant, hidecaption, className, ...props }) => {
  const [instagramHtml, setInstagramHtml] = useState<string>('');

  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('variant', variant, 'default');
  const componentClassName = cn(variantClassName, className);

  useEffect(() => {
    const fetchData = async () => {
      const script = document.createElement('script');
      script.src = 'https://platform.instagram.com/en_US/embeds.js';
      script.async = true;
      document.body.appendChild(script);

      try {
        let response = await http.get(baseUrl, {
          params: {
            appid: 1390493017945983,
            p: postId,
            hc: String(hidecaption),
          },
        });

        setInstagramHtml(response?.data?.html);
      } catch (error) {
        logger.catch('There was an error, so the request failed. Here is the error: ', error);
      }
    };
    fetchData();
  }, [postId, hidecaption]);

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }, [instagramHtml]);

  return <div dangerouslySetInnerHTML={{ __html: instagramHtml }} className={componentClassName} {...props} />;
};

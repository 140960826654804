/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneHeading as ResolvedStandaloneHeading } from 'base/components/standalone/Heading';

export const Heading = ResolvedStandaloneHeading;

export type HeadingProps = PropsFromComponent<typeof Heading>;

export const HeadingWith = (extras: DynamicStandaloneExtras): typeof Heading => {
    return function Heading(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Heading');
        return <ResolvedStandaloneHeading {...mergeProps({ options: { theme } }, props)} />;
    }
}
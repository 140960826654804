/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneSlider as ResolvedStandaloneSlider } from 'base/components/standalone/Slider';

export const Slider = ResolvedStandaloneSlider;

export type SliderProps = PropsFromComponent<typeof Slider>;

export const SliderWith = (extras: DynamicStandaloneExtras): typeof Slider => {
    return function Slider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Slider');
        return <ResolvedStandaloneSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { propClassName } from '@/styles/RecommendedArticles';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';

export interface RecommendedArticlesListProps extends ComponentProps<'ul'> {
  variant?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
}

export const RecommendedArticlesListComponent: Component<RecommendedArticlesListProps> = ({
  variant,
  children,
  size,
  colors,
  className,
  ...props
}) => {
  const variantClassName = propClassName('list_variant', variant, 'default');
  const sizeClassName = propClassName('list_size', size, 'default');
  const colorsClassName = propClassName('list_colors', colors, 'default');

  const componentClassName = cn(variantClassName, sizeClassName, colorsClassName, className);

  return (
    <ul className={componentClassName} {...props}>
      {children}
    </ul>
  );
};

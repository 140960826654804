/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorSecondNativeArticle as ResolvedLabradorSecondNativeArticle } from 'base/components/labrador/SecondNativeArticle';

export const LabradorSecondNativeArticle: typeof ResolvedLabradorSecondNativeArticle = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorSecondNativeArticle {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorSecondNativeArticleProps = PropsFromComponent<typeof LabradorSecondNativeArticle>;

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorTwitter as ResolvedLabradorTwitter } from 'base/components/labrador/Twitter';

export const LabradorTwitter: typeof ResolvedLabradorTwitter = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorTwitter {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorTwitterProps = PropsFromComponent<typeof LabradorTwitter>;

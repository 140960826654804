/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneCourseCountdown as ResolvedStandaloneCourseCountdown } from 'base/components/standalone/CourseCountdown';

export const CourseCountdown = ResolvedStandaloneCourseCountdown;

export type CourseCountdownProps = PropsFromComponent<typeof CourseCountdown>;

export const CourseCountdownWith = (extras: DynamicStandaloneExtras): typeof CourseCountdown => {
    return function CourseCountdown(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'CourseCountdown');
        return <ResolvedStandaloneCourseCountdown {...mergeProps({ options: { theme } }, props)} />;
    }
}
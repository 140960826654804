/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneFrontCoverstory as ResolvedStandaloneFrontCoverstory } from 'base/components/standalone/FrontCoverstory';

export const FrontCoverstory = ResolvedStandaloneFrontCoverstory;

export type FrontCoverstoryProps = PropsFromComponent<typeof FrontCoverstory>;

export const FrontCoverstoryWith = (extras: DynamicStandaloneExtras): typeof FrontCoverstory => {
    return function FrontCoverstory(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'FrontCoverstory');
        return <ResolvedStandaloneFrontCoverstory {...mergeProps({ options: { theme } }, props)} />;
    }
}
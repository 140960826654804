import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ArticleDisclosureLabel } from './ArticleDisclosureLabel';

export interface StandaloneArticleDisclosureLabelProps extends StandaloneComponentProps<typeof ArticleDisclosureLabel> {
  content?: React.ReactNode;
}

export const StandaloneArticleDisclosureLabel: StandaloneComponent<StandaloneArticleDisclosureLabelProps> = ({
  content,
  options,
  ...props
}) => {
  return (
    <ArticleDisclosureLabel {...options} {...props}>
      {content}
    </ArticleDisclosureLabel>
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTimer as ResolvedStandaloneTimer } from 'base/components/standalone/Timer';

export const Timer = ResolvedStandaloneTimer;

export type TimerProps = PropsFromComponent<typeof Timer>;

export const TimerWith = (extras: DynamicStandaloneExtras): typeof Timer => {
    return function Timer(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Timer');
        return <ResolvedStandaloneTimer {...mergeProps({ options: { theme } }, props)} />;
    }
}
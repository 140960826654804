/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorAdunit as ResolvedLabradorAdunit } from 'base/components/labrador/Adunit';

export const LabradorAdunit: typeof ResolvedLabradorAdunit = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorAdunit {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorAdunitProps = PropsFromComponent<typeof LabradorAdunit>;

import { ArticleSlider } from '@/components/ArticleSlider';
import { LabradorComponent } from '@/types/component';
import { Content } from '@/types/content';

import { renderContentComponent } from 'modules/dynamic/components';

export const LabradorArticleSlider: LabradorComponent = ({ type, data, meta, descendants }) => {
  const slides = descendants.map((descendant: Content) => {
    const { data, children } = descendant;

    return {
      caption: data.category,
      description: data.title,
      publishedUrl: data.publishedUrl,
      image: children[0] ? renderContentComponent(children[0]) : undefined,
    };
  });

  return (
    <ArticleSlider
      // Link not supported on labrador yet
      // caption={...}
      // link={...}

      headline={data.title}
      slides={slides}
      options={{ size: data.size }}
    />
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneInstagram as ResolvedStandaloneInstagram } from 'base/components/standalone/Instagram';

export const Instagram = ResolvedStandaloneInstagram;

export type InstagramProps = PropsFromComponent<typeof Instagram>;

export const InstagramWith = (extras: DynamicStandaloneExtras): typeof Instagram => {
    return function Instagram(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Instagram');
        return <ResolvedStandaloneInstagram {...mergeProps({ options: { theme } }, props)} />;
    }
}
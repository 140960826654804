import { Tabs, TabsProps } from '@/components/Tabs';
import { LabradorComponent } from '@/types/component';

import { isEmpty } from 'typesafe-utils';

type Link = {
  link?: string;
  locked?: boolean;
  title?: string;
};

export const LabradorCustomLinks: LabradorComponent = ({ data, type, meta }) => {
  const links: Link[] = data.links ?? [];
  const isCourseFilter = meta.parentRowType === 'courseFilter';

  const tabs: TabsProps['tabs'] = links.map(({ link, locked, title }) => ({
    href: !locked ? link : undefined,
    content: title,
    isActive: isEmpty(link),
    isLocked: locked,
  }));

  return (
    <Tabs
      tabs={tabs}
      options={{
        colors: isCourseFilter ? 'alternative' : 'default',
        size: isCourseFilter ? 'small' : 'large',
      }}
    />
  );
};

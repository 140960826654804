import { ButtonProps } from '@/components/Button';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Banner } from './Banner';

export interface StandaloneBannerProps extends StandaloneComponentProps<typeof Banner> {
  content?: ReactNode;
  backgroundImage?: ReactNode;
  link?: ButtonProps['link'];
}

export const StandaloneBanner: StandaloneComponent<StandaloneBannerProps> = ({
  content,
  backgroundImage,
  options,
  ...props
}) => {
  const { $content, ...$section } = options || {};

  return (
    <Banner {...$section} {...props}>
      {backgroundImage && <Banner.BackgroundImage>{backgroundImage}</Banner.BackgroundImage>}
      <Banner.Content {...$content}>{content}</Banner.Content>
    </Banner>
  );
};

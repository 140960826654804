import { Image, ImageProps } from '@/components/Image';
import { propClassName } from '@/styles/SavedArticles';
import { ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';

export interface SavedArticlesArticleImageProps extends ExtendedStandaloneComponentProps<ImageProps> {}

export const SavedArticlesArticleImageComponent: ExtendedStandaloneComponent<SavedArticlesArticleImageProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const { options, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const colorsClassName = propClassName('article_image_colors', colors, 'default');
  const sizeClassName = propClassName('article_image_size', size, 'default');
  const variantClassName = propClassName('article_image_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName);

  return <Image options={{ className: componentClassName, ...standaloneOptions }} {...standaloneProps} {...props} />;
};

import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import { t } from 'lib/utils';
import mergeProps from 'lib/utils/mergeProps';

export interface VideoArticleHeaderProps extends ComponentProps<'figcaption'> {
  titleTextSize?: {
    desktop: number;
    mobile: number;
  };
}

export const VideoArticleHeaderComponent: Component<VideoArticleHeaderProps> = ({
  titleTextSize,
  children,
  ...props
}) => {
  const className = useClassName('header', props, { className: titleTextSize && t(titleTextSize) });

  return (
    <figcaption itemProp="headline" {...mergeProps({ className }, props)}>
      {children}
    </figcaption>
  );
};

import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { TextTitle } from './TextTitle';

export interface StandaloneTextTitleProps extends StandaloneComponentProps<typeof TextTitle> {
  content: React.ReactNode;
}

export const StandaloneTextTitle: StandaloneComponent<StandaloneTextTitleProps> = ({ content, ...props }) => {
  return <TextTitle {...props}>{content}</TextTitle>;
};

import { Image } from '@/components/Image';
import c from '@/styles/Byline';
import { ClassNameProp, Component } from '@/types/component';
import getUrl from '@/utils/getUrl';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import mergeProps from 'lib/utils/mergeProps';
import { ImageProps as NextImageProps } from 'next/image';

const maybeFixImageSize = (image: NextImageProps['src']) => {
  if (typeof image !== 'string') {
    return image;
  }

  const imageUrl = getUrl(image);
  if (!imageUrl) return image;

  const width = parseInt(imageUrl.searchParams.get('width') || '');
  const height = parseInt(imageUrl.searchParams.get('height') || '');

  if (width > 144) {
    imageUrl.searchParams.set('width', '144');
    imageUrl.searchParams.set('height', String(height * (144 / width)));
  }

  return imageUrl.href;
};

export interface BylineImageProps extends Omit<NextImageProps, 'variant'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

export const BylineImageComponent: Component<BylineImageProps> = ({ className, variant, colors, size, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('image_variant', variant, 'default');
  const sizeClassName = getPropStyles('image_size', size, 'default');
  const colorsClassName = getPropStyles('image_colors', colors, 'default');
  const componentClassName = cn('rounded-full', variantClassName, sizeClassName, colorsClassName, className);
  const fixedSrc = maybeFixImageSize(props.src);

  return (
    <Image
      {...mergeProps(
        {
          options: {
            className: componentClassName,
            size: 'none',
            fetchPriority: 'high',
            src: fixedSrc,
            unoptimized: true,
          },
        },
        props as any,
      )}
    />
  );
};

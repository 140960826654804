import { Tag, TagProps } from '@/components/Tag';
import { componentTheme } from '@/styles/SavedArticles';
import { mergeProps } from '@/utils/merge';
import type { StandaloneComponent } from 'types/component';

export type SavedArticlesTagProps = TagProps;

export const SavedArticlesTagComponent: StandaloneComponent<TagProps> = (props) => {
  return (
    <Tag
      {...mergeProps(
        {
          options: {
            theme: componentTheme('tag'),
          },
        },
        props,
      )}
    />
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { FirstFrame } from '../constants/playEvents';
import { usePlayerEvent } from './usePlayerEvent';

export default function useOrientationTracking({ player }: { player: any }) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const listenerTriggered = useRef(false);

  const onVideoStart = useCallback(() => {
    setIsVideoPlaying(true);
  }, []);

  const onOrientationChange = (event: Event) => {
    listenerTriggered.current = true;
    window.cts.send.event('action', 'passive', `video_orientation_${(event.target as ScreenOrientation)?.type}`);
  };

  usePlayerEvent(player, FirstFrame, onVideoStart);

  useEffect(() => {
    if (isVideoPlaying && !listenerTriggered.current) {
      screen.orientation?.addEventListener('change', onOrientationChange);
    }

    return () => {
      screen.orientation?.removeEventListener('change', onOrientationChange);
    };
  }, [isVideoPlaying]);

  return null;
}

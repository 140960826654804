import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { stripHtml } from 'lib/utils/stripHtml';
import { useJWPlayer } from './JWPlayerContext';

export interface VideoArticleHeaderTitleProps extends ComponentProps<'span'> {
  mediaId?: string;
}

export const VideoArticleHeaderTitleComponent: Component<VideoArticleHeaderTitleProps> = ({
  mediaId,
  children,
  ...props
}) => {
  const className = useClassName('header_title', props);

  const { currentMedia } = useJWPlayer();

  const videoHasChanged = currentMedia?.mediaid && currentMedia?.mediaid !== mediaId;
  const videoTitle = stripHtml(currentMedia?.title);

  return <span {...mergeProps({ className }, props)}>{videoHasChanged ? videoTitle : children}</span>;
};

import useLazyCallback from 'lib/hooks/useLazyCallback';

import logger from 'lib/utils/logger';
import { useCallback, useEffect, useState } from 'react';
import { useJWPlayer } from './JWPlayerContext';
import { setupPlayer } from './setup/setupPlayer';
import { VideoPlayerType } from './types/video';

export function VideoArticleJWPlayerComponent(props: VideoPlayerType & { className?: 'string' }) {
  const { className, disableLazyLoad, videoPlacementPriority, ...restProps } = props;
  const [videoId, setVideoId] = useState(disableLazyLoad ? props.videoId : '');

  const { onPlayerSet, setVideoPlacementPriority } = useJWPlayer();

  const setVideoIdInView = useCallback(() => {
    if (!videoId) {
      setVideoId(props.videoId);
    }
  }, [props.videoId, videoId]);

  const videoRef = useLazyCallback<HTMLDivElement>(setVideoIdInView);

  const getPlayerInstance = useCallback(async () => {
    try {
      const instance = await setupPlayer(restProps);
      onPlayerSet(instance);
      setVideoPlacementPriority(videoPlacementPriority as string[]);
    } catch (error) {
      logger.catch('Error during setting up player instance', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPlayerSet]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoId && window.jwplayer) {
        clearInterval(interval);
        getPlayerInstance();
      }
    });

    return () => clearInterval(interval);
  }, [getPlayerInstance, videoId]);

  return <div ref={videoRef} className={className} id={videoId}></div>;
}

import { useStableOptions } from '@/hooks/useStable';
import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { isReactNode } from '@/types/is';
import { AccessLevel } from '@/utils/parseAccessLevel';
import { useMemo } from 'react';
import { ContentLock } from './ContentLock';

export interface StandaloneContentLockProps extends StandaloneComponentProps<typeof ContentLock> {
  accessLevel?: AccessLevel;
  content?: {
    login?:
      | React.ReactNode
      | {
          button?: React.ReactNode;
          caption?: React.ReactNode;
          footer?: React.ReactNode;
          headline?: React.ReactNode;
        };
    purchase?: React.ReactNode;
  };
}

export const StandaloneContentLock: StandaloneComponent<StandaloneContentLockProps> = ({
  accessLevel,
  content,
  options,
  ...props
}) => {
  const { $login: loginOptions, $purchase, ...$base } = useStableOptions(options);
  const {
    $button: $loginButton,
    $caption: $loginCaption,
    $footer: $loginFooter,
    $headline: $loginHeadline,
    ...$login
  } = useStableOptions(loginOptions);

  const { logInUrl } = useUserAuth();

  const loginButton = useMemo(
    () => (
      <ContentLock.Login.Button
        link={{ href: logInUrl }}
        options={{ size: 'large', ...$loginButton }}
        content={isReactNode(content?.login) || !content?.login?.button ? 'Logga in' : content?.login?.button}
      />
    ),
    [$loginButton, content?.login, logInUrl],
  );

  if (accessLevel === 'open') {
    return null;
  }

  return (
    <ContentLock {...$base} {...props}>
      {accessLevel === 'login' && (
        <ContentLock.Login {...$login}>
          {isReactNode(content?.login) ? (
            content?.login
          ) : (
            <>
              <ContentLock.Login.Headline {...$loginHeadline}>
                {content?.login?.headline ?? 'Vill du läsa resten av den här artikeln?'}
              </ContentLock.Login.Headline>
              <ContentLock.Login.Caption {...$loginCaption}>
                {content?.login?.caption ??
                  'För att få ut det mesta av din läsupplevelse, skapa ett konto och logga in — det är helt gratis!'}
              </ContentLock.Login.Caption>
              {loginButton}
              <ContentLock.Login.Footer {...$loginFooter}>
                {content?.login?.footer ?? 'Eller skapa ditt konto gratis idag'}
              </ContentLock.Login.Footer>
            </>
          )}
        </ContentLock.Login>
      )}

      {accessLevel === 'purchase' && <ContentLock.Purchase {...$purchase}>{content?.purchase}</ContentLock.Purchase>}
    </ContentLock>
  );
};

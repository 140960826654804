import { ClassNameProvider } from '@/styles/LoopAround';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Fragment, ReactNode } from 'react';
import { isUndefined } from 'typesafe-utils';
import { LoopAround } from './LoopAround';

export interface StandaloneLoopAroundProps extends StandaloneComponentProps<typeof LoopAround> {
  velocity?: number;
  fragment?: ReactNode;
  length?: number;
}

export const StandaloneLoopAround: StandaloneComponent<StandaloneLoopAroundProps> = ({
  velocity = -2,
  fragment,
  length = 20,
  options,
  ...props
}) => {
  const { $reel, ...$base } = options ?? {};

  const vector = velocity / Math.abs(velocity);
  const edge = ((vector * 100) / length) * (velocity > 0 ? -1 : 1);

  const from = velocity > 0 ? edge : 0;
  const to = velocity > 0 ? 0 : edge;
  const duration = (3 / Math.abs(velocity)).toPrecision(2);

  const style = {
    '--loop-from': `${from}%`,
    '--loop-to': `${to}%`,
    '--loop-duration': `${duration}s`,
  } as React.CSSProperties;

  if (isUndefined(fragment)) {
    return null;
  }

  return (
    <ClassNameProvider value={$base} options={{ fallbacks: { colors: 'variant', size: 'variant' } }}>
      <LoopAround {...$base} {...props}>
        <LoopAround.Reel {...{ style }} {...$reel}>
          {Array.from({ length }, (_, index) => (
            <Fragment key={index}>{fragment}</Fragment>
          ))}
        </LoopAround.Reel>
      </LoopAround>
    </ClassNameProvider>
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneArticleTeaserBodytext as ResolvedStandaloneArticleTeaserBodytext } from 'base/components/standalone/ArticleTeaser/Bodytext';

export const ArticleTeaserBodytext = ResolvedStandaloneArticleTeaserBodytext;

export type ArticleTeaserBodytextProps = PropsFromComponent<typeof ArticleTeaserBodytext>;

export const ArticleTeaserBodytextWith = (extras: DynamicStandaloneExtras): typeof ArticleTeaserBodytext => {
    return function ArticleTeaserBodytext(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleTeaserBodytext');
        return <ResolvedStandaloneArticleTeaserBodytext {...mergeProps({ options: { theme } }, props)} />;
    }
}
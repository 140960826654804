import c from '@/styles/TextArea';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { TextAreaVariants } from '.';

export interface TextAreaFieldGroupProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<TextAreaVariants>;
}

export const TextAreaFieldGroupComponent: Component<TextAreaFieldGroupProps> = ({
  className,
  variant,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('field_group_variant', variant, 'default');
  const componentClassName = cn(variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorRadioButton as ResolvedLabradorRadioButton } from 'base/components/labrador/RadioButton';

export const LabradorRadioButton: typeof ResolvedLabradorRadioButton = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorRadioButton {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorRadioButtonProps = PropsFromComponent<typeof LabradorRadioButton>;

import { tw } from '@/utils/tw';

const Accordion = tw.theme({
  slots: {
    base: `group/accordion`,
    content: `hidden group-data-[open]/accordion:block`,
    group: `flex items-center justify-between`,
    toggle: `cursor-pointer transition-transform group-data-[open]/accordion:[transform:rotateX(180deg)]`,
    headline: ``,
  },
});

export default Accordion;

import { useClassName } from '@/styles/Banner';
import { GenericComponentFactory } from 'base/components/GenericComponent';

const GenericComponent = GenericComponentFactory({ useClassName });

const Section = GenericComponent({ as: 'section', styles: { key: '' } });
const BackgroundImage = GenericComponent({ as: 'picture', styles: { key: 'backgroundImage' } });
const Content = GenericComponent({ as: 'div', styles: { key: 'content' } });

export const Banner = Object.assign(Section, {
  BackgroundImage,
  Content: Content,
});

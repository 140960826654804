import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { useJWPlayer } from './JWPlayerContext';
import DurationIcon from './icons/duration-icon.svg';
import formatMinutes from './utils/formatMinutes';

export interface VideoArticleVideoContainerDurationProps extends ComponentProps<'div'> {
  mediaId?: string;
}

export const VideoArticleVideoContainerDurationComponent: Component<VideoArticleVideoContainerDurationProps> = ({
  mediaId,
  children,
  ...props
}) => {
  const { currentMedia } = useJWPlayer();
  const className = useClassName('videoContainer_duration', props);

  // Don't set title on initial video, as title prop can be customized
  // by editors in Labrador.

  const videoHasChanged = currentMedia?.mediaid && currentMedia?.mediaid !== mediaId;
  const videoDuration = formatMinutes(currentMedia?.duration as any);
  return (
    <>
      {videoDuration && (
        <div {...mergeProps({ className }, props)}>
          <span>
            <DurationIcon />
          </span>
          {videoHasChanged ? videoDuration : children}
        </div>
      )}
    </>
  );
};

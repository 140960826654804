import { Link } from '@/components/Link';
import { ClassNameProvider } from '@/styles/ExpertSlider';
import HelenaWiklundImage from '@/svenskdam/public/image/experts/helena-wiklund.png';
import HermanLindqvistImage from '@/svenskdam/public/image/experts/herman-lindqvist.png';
import JohanTLindwallImage from '@/svenskdam/public/image/experts/johan-t-lindwall.png';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { withOptions } from 'lib/utils/withOptions';
import type { ImageProps as NextImageProps } from 'next/image';
import { ExpertSlider } from './ExpertSlider';

const headline: React.ReactNode = 'Våra Hovexperter';

type Slide = {
  image: NextImageProps;
  caption: React.ReactNode;
  description: React.ReactNode;
  href: string;
};

const slides: Slide[] = [
  {
    image: { src: HermanLindqvistImage, alt: 'Historiker' },
    caption: 'Historiker',
    description: 'Välkända Herman Lindqvist berättar om kungarnas och drottningarnas historia',
    href: '/vara-skribenter/herman_lindqvist',
  },
  {
    image: { src: HelenaWiklundImage, alt: 'Hovreporter' },
    caption: 'Hovreporter',
    description: 'Vassa Helena Wiklund rapporterar om allt det senaste från insidan av slottet',
    href: '/vara-skribenter/helena_wiklund',
  },
  {
    image: { src: JohanTLindwallImage, alt: 'Chefredaktör' },
    caption: 'Chefredaktör',
    description: 'Johan T. Lindwall är vår kunniga chefredaktör. Det han inte vet om hovet är inte värt att veta',
    href: '/vara-skribenter/johan_t_lindwall',
  },
];

export type SvenskdamStandaloneExpertSliderProps = StandaloneComponentProps<typeof ExpertSlider>;

export const SvenskdamStandaloneExpertSlider: StandaloneComponent<SvenskdamStandaloneExpertSliderProps> = ({
  options,
  ...props
}) => {
  return (
    <ClassNameProvider value={options}>
      <ExpertSlider {...withOptions(options)} {...props}>
        <ExpertSlider.Headline {...withOptions(options?.$headline)}>{headline}</ExpertSlider.Headline>
        <ExpertSlider.Slider
          slides-per-view="auto"
          space-between="12"
          suppressHydrationWarning
          {...withOptions(options?.$slider)}
        >
          {slides.map((slide, index) => (
            <ExpertSlider.Slide key={index} {...withOptions(options?.$slide)}>
              <Link
                href={slide.href}
                content={
                  <>
                    <ExpertSlider.Slide.Image unoptimized {...withOptions(options?.$slide?.$image)} {...slide.image} />

                    <ExpertSlider.Slide.Caption {...withOptions(options?.$slide?.$caption)}>
                      {slide.caption}
                    </ExpertSlider.Slide.Caption>

                    <ExpertSlider.Slide.Description {...withOptions(options?.$slide?.$description)}>
                      {slide.description}
                    </ExpertSlider.Slide.Description>
                  </>
                }
              />
            </ExpertSlider.Slide>
          ))}
        </ExpertSlider.Slider>
      </ExpertSlider>
    </ClassNameProvider>
  );
};

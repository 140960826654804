/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { SvenskdamLabradorTrendingMenu as ResolvedLabradorTrendingMenu } from 'sites/svenskdam/components/labrador/TrendingMenu';

export const LabradorTrendingMenu: typeof ResolvedLabradorTrendingMenu = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorTrendingMenu {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorTrendingMenuProps = PropsFromComponent<typeof LabradorTrendingMenu>;

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneColumns as ResolvedStandaloneColumns } from 'base/components/standalone/Columns';

export const Columns = ResolvedStandaloneColumns;

export type ColumnsProps = PropsFromComponent<typeof Columns>;

export const ColumnsWith = (extras: DynamicStandaloneExtras): typeof Columns => {
    return function Columns(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Columns');
        return <ResolvedStandaloneColumns {...mergeProps({ options: { theme } }, props)} />;
    }
}
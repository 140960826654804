/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTextTitle as ResolvedStandaloneTextTitle } from 'base/components/standalone/TextTitle';

export const TextTitle = ResolvedStandaloneTextTitle;

export type TextTitleProps = PropsFromComponent<typeof TextTitle>;

export const TextTitleWith = (extras: DynamicStandaloneExtras): typeof TextTitle => {
    return function TextTitle(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TextTitle');
        return <ResolvedStandaloneTextTitle {...mergeProps({ options: { theme } }, props)} />;
    }
}
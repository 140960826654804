import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Box, BoxProps } from './Box';

export interface StandaloneBoxProps extends StandaloneComponentProps {
  content?: ReactNode;
  options?: BoxProps;
}

export const StandaloneBox: StandaloneComponent<StandaloneBoxProps> = ({ content, options, ...props }) => {
  return (
    <Box {...options} {...props}>
      {content}
    </Box>
  );
};

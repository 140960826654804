/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneBox as ResolvedStandaloneBox } from 'base/components/standalone/Box';

export const Box = ResolvedStandaloneBox;

export type BoxProps = PropsFromComponent<typeof Box>;

export const BoxWith = (extras: DynamicStandaloneExtras): typeof Box => {
    return function Box(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Box');
        return <ResolvedStandaloneBox {...mergeProps({ options: { theme } }, props)} />;
    }
}
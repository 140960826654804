import { Button, ButtonProps } from '@/components/Button';
import { propClassName } from '@/styles/NewsletterSignup';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { useNewsletterSignupState } from './NewsletterSignup';

export interface NewsletterSignupButtonProps extends ExtendedStandaloneComponentProps<ButtonProps> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
}

export const NewsletterSignupButtonComponent: ExtendedStandaloneComponent<NewsletterSignupButtonProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const {
    formState: { isSubmitting },
  } = useNewsletterSignupState();

  const { options, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const colorsClassName = propClassName('button_colors', colors, 'primary');
  const sizeClassName = propClassName('button_size', size, 'default');
  const variantClassName = propClassName('button_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  return (
    <Button
      options={{
        className: componentClassName,
        isFullWidth: true,
        type: 'submit',
        disabled: isSubmitting,
        ...standaloneOptions,
      }}
      {...standaloneProps}
      {...props}
    />
  );
};

import { Tag, TagProps } from '@/components/Tag';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ArticleFooter } from './ArticleFooter';

export interface StandaloneArticleFooterProps
  extends StandaloneComponentProps<typeof ArticleFooter, { $tag?: TagProps }> {
  tags?: TagProps[];
}

export const StandaloneArticleFooter: StandaloneComponent<StandaloneArticleFooterProps> = ({ tags, options }) => {
  const { $line, $tags, $tag, ...$base } = options ?? {};

  return (
    <ArticleFooter {...$base}>
      <ArticleFooter.Line {...$line} />
      <ArticleFooter.Tags {...$tags}>
        {tags?.map((tag, index) => (
          <Tag
            key={index}
            value={tag.value}
            link={tag.link}
            {...mergeProps(
              {
                options: {
                  size: 'medium',
                },
              },
              $tag,
            )}
          />
        ))}
      </ArticleFooter.Tags>
    </ArticleFooter>
  );
};

import { componentClassName } from '@/styles/BreakingNews';

import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { useBreakingNewsContext } from './BreakingNews';

export interface BreakingNewsItemProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default' | 'alternative'>;
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
}

export const BreakingNewsItemComponent: Component<BreakingNewsItemProps> = ({
  variant,
  size,
  colors,
  children,
  ...props
}) => {
  const { theme, ...contextClassNameProps } = useBreakingNewsContext({ colors, size, variant });
  const className = componentClassName('item', contextClassNameProps, { theme });

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

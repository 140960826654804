/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneStrossle as ResolvedStandaloneStrossle } from 'base/components/standalone/Strossle';

export const Strossle = ResolvedStandaloneStrossle;

export type StrossleProps = PropsFromComponent<typeof Strossle>;

export const StrossleWith = (extras: DynamicStandaloneExtras): typeof Strossle => {
    return function Strossle(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Strossle');
        return <ResolvedStandaloneStrossle {...mergeProps({ options: { theme } }, props)} />;
    }
}
/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { SvenskdamStandaloneExpertSlider as ResolvedStandaloneExpertSlider } from 'sites/svenskdam/components/standalone/Expert/Slider';

export const ExpertSlider = ResolvedStandaloneExpertSlider;

export type ExpertSliderProps = PropsFromComponent<typeof ExpertSlider>;

export const ExpertSliderWith = (extras: DynamicStandaloneExtras): typeof ExpertSlider => {
    return function ExpertSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ExpertSlider');
        return <ResolvedStandaloneExpertSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}
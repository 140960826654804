/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { SvenskdamStandaloneNewsletterSignup as ResolvedStandaloneNewsletterSignup } from 'sites/svenskdam/components/standalone/NewsletterSignup';

export const NewsletterSignup = ResolvedStandaloneNewsletterSignup;

export type NewsletterSignupProps = PropsFromComponent<typeof NewsletterSignup>;

export const NewsletterSignupWith = (extras: DynamicStandaloneExtras): typeof NewsletterSignup => {
    return function NewsletterSignup(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'NewsletterSignup');
        return <ResolvedStandaloneNewsletterSignup {...mergeProps({ options: { theme } }, props)} />;
    }
}
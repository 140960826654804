import { useClassName } from '@/styles/SavedArticles';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export interface SavedArticlesEmptyProps extends ComponentProps<'p'> {}

export const SavedArticlesEmptyComponent: Component<SavedArticlesEmptyProps> = ({ children, ...props }) => {
  const className = useClassName('empty', props);

  const defaultFallback = (
    <>
      <span>
        Du har inga sparade artiklar än, för att spara en artikel så klickar du på knappen “spara artikel” i en artikel.
      </span>
      <span>
        Besök{' '}
        <a href="/" className="font-bold hover:underline">
          vår startsida
        </a>{' '}
        för att hitta artiklar du gillar.
      </span>
    </>
  );

  return <p {...mergeProps({ className }, props)}>{children ?? defaultFallback}</p>;
};

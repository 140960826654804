/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneList as ResolvedStandaloneList } from 'base/components/standalone/List';

export const List = ResolvedStandaloneList;

export type ListProps = PropsFromComponent<typeof List>;

export const ListWith = (extras: DynamicStandaloneExtras): typeof List => {
    return function List(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'List');
        return <ResolvedStandaloneList {...mergeProps({ options: { theme } }, props)} />;
    }
}
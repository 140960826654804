/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneYouplay as ResolvedStandaloneYouplay } from 'base/components/standalone/Youplay';

export const Youplay = ResolvedStandaloneYouplay;

export type YouplayProps = PropsFromComponent<typeof Youplay>;

export const YouplayWith = (extras: DynamicStandaloneExtras): typeof Youplay => {
    return function Youplay(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Youplay');
        return <ResolvedStandaloneYouplay {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { Tag as TagStandalone } from '@/components/Tag';
import { TrendingMenuTheme } from '@/components/TrendingMenu/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: TrendingMenuTheme });

const Base = $({ as: 'section', slot: 'base' });
const Headline = $({ as: 'span', slot: 'headline' });
const Tag = GenericSlot({ as: TagStandalone, theme: TrendingMenuTheme.Tag });

export const TrendingMenu = Object.assign(Base, {
  Headline,
  Tag,
});

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneSliderSlide as ResolvedStandaloneSliderSlide } from 'base/components/standalone/Slider/Slide';

export const SliderSlide = ResolvedStandaloneSliderSlide;

export type SliderSlideProps = PropsFromComponent<typeof SliderSlide>;

export const SliderSlideWith = (extras: DynamicStandaloneExtras): typeof SliderSlide => {
    return function SliderSlide(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'SliderSlide');
        return <ResolvedStandaloneSliderSlide {...mergeProps({ options: { theme } }, props)} />;
    }
}
/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTiktok as ResolvedStandaloneTiktok } from 'base/components/standalone/Tiktok';

export const Tiktok = ResolvedStandaloneTiktok;

export type TiktokProps = PropsFromComponent<typeof Tiktok>;

export const TiktokWith = (extras: DynamicStandaloneExtras): typeof Tiktok => {
    return function Tiktok(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Tiktok');
        return <ResolvedStandaloneTiktok {...mergeProps({ options: { theme } }, props)} />;
    }
}
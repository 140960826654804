import { useClassName } from '@/styles/ShowsSlider';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import PlayIcon from '../JwVideo/Teaser/icons/play-icon.svg';

export interface ShowsSliderSlideProps extends ComponentProps<'div'> {
  $icon?: ComponentProps<'svg'>;
}

export const ShowsSliderSlideComponent: Component<ShowsSliderSlideProps> = ({ children, $icon, ...props }) => {
  const className = useClassName('slide', props);
  const playIconClassName = useClassName('playIcon', props);

  return (
    <div {...mergeProps({ className }, props)}>
      {children}
      <PlayIcon className={playIconClassName} {...$icon} />
    </div>
  );
};

import { Icon } from '@/components/Icon';
import { NewsletterSignupProps } from '@/components/NewsletterSignup';

export const standaloneNewsletterSignupDefaults: Omit<NewsletterSignupProps, 'options'> = {
  error: 'Tyvärr, registreringen för nyhetsbrev misslyckades. Försök igen senare.',
  description: `Håll dig uppdaterad om det senaste inom resor och äventyr. Vi skickar dig tips och råd om hur du får den perfekta resan!`,
  footer: (
    <>
      För mer information om hur vi behandlar din data se <a href="#">Aller Medias integritetspolicy</a>.
    </>
  ),
  headline: `Skaffa vårt nyhetsbrev!`,
  inputs: {
    checkboxes: [
      {
        $standalone: { label: `Äventyrsbrevet`, name: 'newsletter', value: 'lorem' },
        rules: { required: '* Välj ett eller flera nyhetsbrev' },
      },
      {
        $standalone: { label: `Allt om resor`, name: 'newsletter', value: 'ipsum' },
        rules: { required: '* Välj ett eller flera nyhetsbrev' },
      },
    ],
    fields: [
      {
        $standalone: {
          label: `Förnamn`,
          name: 'firstname',
          options: { variant: 'alternative' },
        },
        rules: { required: 'Detta fält är obligatoriskt' },
      },
      {
        $standalone: {
          label: `Efternamn`,
          name: 'lastname',
          options: { variant: 'alternative' },
        },
        rules: { required: 'Detta fält är obligatoriskt' },
      },
      {
        $standalone: {
          label: `E-post`,
          name: 'email',
          options: { $input: { type: 'email' }, variant: 'alternative' },
        },
        rules: { required: 'Kontrollera att e-postadressen är rätt' },
      },
    ],
  },
  submitButton: { content: `Ja tack!` },
  successModal: {
    content: `<h2>Bekräfta din mejl</h2><p>Vi har skickat ett mejl till din e-postadress. Öppna mejlet och bekräfta din e-postadress så får du nyhetsbrevet inom kort.</p>`,
    icon: <Icon name="sent" options={{ size: 'large' }} />,
  },
};

import { componentClassName } from '@/styles/BreakingNews';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { useBreakingNewsContext } from './BreakingNews';

export interface BreakingNewsItemLabelProps extends ComponentProps<'span'> {
  variant?: ClassNameProp<'default' | 'alternative'>;
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
}

export const BreakingNewsItemLabelComponent: Component<BreakingNewsItemLabelProps> = ({
  variant,
  size,
  colors,
  children,
  ...props
}) => {
  const { theme, ...contextClassNameProps } = useBreakingNewsContext({ colors, size, variant });
  const className = componentClassName('item_label', contextClassNameProps, { theme });

  return <span {...mergeProps({ className }, props)}>{children}</span>;
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorAdTemplate as ResolvedLabradorAdTemplate } from 'base/components/labrador/AdTemplate';

export const LabradorAdTemplate: typeof ResolvedLabradorAdTemplate = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorAdTemplate {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorAdTemplateProps = PropsFromComponent<typeof LabradorAdTemplate>;

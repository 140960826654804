import { http } from '@/utils/http';
import { StandaloneNewsletterSignup, StandaloneNewsletterSignupProps } from 'base/components/NewsletterSignup';
import { standaloneNewsletterSignupDefaults } from 'base/components/NewsletterSignup/NewsletterSignup.constants';
import deepmerge from 'deepmerge';
import { isProduction } from 'lib/labrador/utils';
import getUrl from 'lib/utils/getUrl';
import logger from 'lib/utils/logger';
import { FieldValues, SubmitHandler } from 'react-hook-form';

export const SvenskdamStandaloneNewsletterSignup: typeof StandaloneNewsletterSignup = ({
  inputs,
  options,
  ...props
}) => {
  const fields = standaloneNewsletterSignupDefaults.inputs?.fields;

  const onSubmitValid: SubmitHandler<FieldValues> = async (data) => {
    const newsletterSignupUrl = getUrl(
      isProduction
        ? 'https://se-labrador-helper-services.labrador.allermedia.io/newsletter/signup'
        : 'https://se-labrador-helper-services-stage.labrador.allermedia.io/newsletter/signup',
    );

    if (!newsletterSignupUrl) {
      logger.error('Could not create URL for newsletter signup');
      return;
    }

    const params = {
      u: '3',
      f: '3',
      s: '',
      c: '0',
      m: '0',
      act: 'sub',
      v: '2',
      'field[7]': 'TRUE',
      'field[50]': 'TRUE',
      jsonp: 'true',
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
    };

    newsletterSignupUrl.search = new URLSearchParams(params).toString();

    const response = await http.get(newsletterSignupUrl.href);

    if (response?.status === 200) {
      return response;
    }

    throw response;
  };

  return (
    <StandaloneNewsletterSignup
      options={deepmerge<StandaloneNewsletterSignupProps['options']>(options, {
        $button: {
          size: 'large',
          colors: 'primary',
        },
        onSubmitValid,
      })}
      inputs={{
        checkboxes: [],
        fields,
      }}
      {...props}
    />
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneRadioButton as ResolvedStandaloneRadioButton } from 'base/components/standalone/RadioButton';

export const RadioButton = ResolvedStandaloneRadioButton;

export type RadioButtonProps = PropsFromComponent<typeof RadioButton>;

export const RadioButtonWith = (extras: DynamicStandaloneExtras): typeof RadioButton => {
    return function RadioButton(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'RadioButton');
        return <ResolvedStandaloneRadioButton {...mergeProps({ options: { theme } }, props)} />;
    }
}
/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneRichTextBox as ResolvedStandaloneRichTextBox } from 'base/components/standalone/RichTextBox';

export const RichTextBox = ResolvedStandaloneRichTextBox;

export type RichTextBoxProps = PropsFromComponent<typeof RichTextBox>;

export const RichTextBoxWith = (extras: DynamicStandaloneExtras): typeof RichTextBox => {
    return function RichTextBox(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'RichTextBox');
        return <ResolvedStandaloneRichTextBox {...mergeProps({ options: { theme } }, props)} />;
    }
}
/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneLineBreak as ResolvedStandaloneLineBreak } from 'base/components/standalone/LineBreak';

export const LineBreak = ResolvedStandaloneLineBreak;

export type LineBreakProps = PropsFromComponent<typeof LineBreak>;

export const LineBreakWith = (extras: DynamicStandaloneExtras): typeof LineBreak => {
    return function LineBreak(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'LineBreak');
        return <ResolvedStandaloneLineBreak {...mergeProps({ options: { theme } }, props)} />;
    }
}
/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneProse as ResolvedStandaloneProse } from 'base/components/standalone/Prose';

export const Prose = ResolvedStandaloneProse;

export type ProseProps = PropsFromComponent<typeof Prose>;

export const ProseWith = (extras: DynamicStandaloneExtras): typeof Prose => {
    return function Prose(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Prose');
        return <ResolvedStandaloneProse {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { propClassName } from '@/styles/AuthorsList';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils';
import { AuthorsListDescriptionComponent } from './AuthorsList.Description';
import { AuthorsListGroupComponent } from './AuthorsList.Group';
import { AuthorsListHeadlineComponent } from './AuthorsList.Headline';
import { AuthorsListItemComponent } from './AuthorsList.Item';
import { AuthorsListItemIconComponent } from './AuthorsList.Item.Icon';
import { AuthorsListItemImageComponent } from './AuthorsList.Item.Image';
import { AuthorsListItemTitleComponent } from './AuthorsList.Item.Title';

export interface AuthorsListProps extends ComponentProps<'section'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

const AuthorsListComponent: Component<AuthorsListProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('colors', colors);
  const sizeClassName = propClassName('size', size);
  const variantClassName = propClassName('variant', variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <section className={componentClassName} {...props}>
      {children}
    </section>
  );
};

export const AuthorsList = Object.assign(AuthorsListComponent, {
  Description: AuthorsListDescriptionComponent,
  Group: AuthorsListGroupComponent,
  Headline: AuthorsListHeadlineComponent,
  Item: Object.assign(AuthorsListItemComponent, {
    Icon: AuthorsListItemIconComponent,
    Image: AuthorsListItemImageComponent,
    Title: AuthorsListItemTitleComponent,
  }),
});

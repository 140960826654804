/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorJwVideoArticle as ResolvedLabradorJwVideoArticle } from 'base/components/labrador/JwVideo/Article';

export const LabradorJwVideoArticle: typeof ResolvedLabradorJwVideoArticle = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorJwVideoArticle {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorJwVideoArticleProps = PropsFromComponent<typeof LabradorJwVideoArticle>;

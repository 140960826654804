/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorHeading as ResolvedLabradorHeading } from 'base/components/labrador/Heading';

export const LabradorHeading: typeof ResolvedLabradorHeading = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorHeading {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorHeadingProps = PropsFromComponent<typeof LabradorHeading>;

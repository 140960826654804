import c from '@/styles/Byline';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface BylineTextProps extends ComponentProps<'span'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

export const BylineTextComponent: Component<BylineTextProps> = ({
  className,
  variant,
  colors,
  size,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('text_variant', variant, 'default');
  const colorsClassName = getPropStyles('text_colors', colors, 'default');
  const sizeClassName = getPropStyles('text_size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <span className={componentClassName} {...props}>
      {children}
    </span>
  );
};

import c from '@/styles/MostRead';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface MostReadSlideCaptionProps extends ComponentProps<'p'> {
  colors?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

export const MostReadSlideCaptionComponent: Component<MostReadSlideCaptionProps> = ({
  children,
  className,
  colors,
  variant,
  size,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('slide_caption_variant', variant, 'default');
  const colorsClassName = getPropStyles('slide_caption_colors', colors, 'default');
  const sizeClassName = getPropStyles('slide_caption_size', size, 'default');

  const componentClassName = cn(colorsClassName, variantClassName, sizeClassName, className);

  return (
    <p className={componentClassName} {...props}>
      {children}
    </p>
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneAuthorsList as ResolvedStandaloneAuthorsList } from 'base/components/standalone/AuthorsList';

export const AuthorsList = ResolvedStandaloneAuthorsList;

export type AuthorsListProps = PropsFromComponent<typeof AuthorsList>;

export const AuthorsListWith = (extras: DynamicStandaloneExtras): typeof AuthorsList => {
    return function AuthorsList(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'AuthorsList');
        return <ResolvedStandaloneAuthorsList {...mergeProps({ options: { theme } }, props)} />;
    }
}
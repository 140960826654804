/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorEmphasis as ResolvedLabradorEmphasis } from 'base/components/labrador/Emphasis';

export const LabradorEmphasis: typeof ResolvedLabradorEmphasis = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorEmphasis {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorEmphasisProps = PropsFromComponent<typeof LabradorEmphasis>;

import c from '@/styles/Youplay';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { YouplayNotFoundComponent } from './Youplay.NotFound';
import { YouplayNotFoundTextComponent } from './Youplay.NotFound.Text';

export interface YouplayProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
}

const YouplayComponent: Component<YouplayProps> = ({ variant, children, className, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);
  const variantClassName = getPropStyles('variant', variant, 'default');

  const componentClassName = cn(variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const Youplay = Object.assign(YouplayComponent, {
  NotFound: Object.assign(YouplayNotFoundComponent, { Text: YouplayNotFoundTextComponent }),
});

import { propClassName } from '@/styles/Quotebox';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { useQuoteboxContext } from './Quotebox';

export interface QuoteboxAuthorProps extends ComponentProps<'span'> {
  variant?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp;
  colors?: ClassNameProp;
}

export const QuoteboxAuthorComponent: Component<QuoteboxAuthorProps> = ({
  children,
  className,
  variant,
  size,
  colors,
  ...props
}) => {
  const context = useQuoteboxContext({ variant, size, colors });

  const variantClassName = propClassName('author_variant', context.variant, 'primary');
  const sizeClassName = propClassName('author_size', context.size);
  const colorsClassName = propClassName('author_colors', context.colors);

  const componentClassName = cn(variantClassName, sizeClassName, colorsClassName, className);

  return (
    <span className={componentClassName} {...props}>
      {children}
    </span>
  );
};

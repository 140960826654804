/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorAffiliateGallery as ResolvedLabradorAffiliateGallery } from 'base/components/labrador/AffiliateGallery';

export const LabradorAffiliateGallery: typeof ResolvedLabradorAffiliateGallery = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorAffiliateGallery {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorAffiliateGalleryProps = PropsFromComponent<typeof LabradorAffiliateGallery>;

import { propClassName } from '@/styles/ArticleSlider';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { ArticleSliderDefaultSize, ArticleSliderSizes } from '.';
import { ArticleSliderArrowComponent } from './ArticleSlider.Arrow';
import { ArticleSliderLinkComponent } from './ArticleSlider.Link';
import { ArticleSliderNavigationComponent } from './ArticleSlider.Navigation';
import { ArticleSliderNavigationItemComponent } from './ArticleSlider.Navigation.Item';
import { ArticleSliderSliderComponent } from './ArticleSlider.Slider';

export interface ArticleSliderProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<ArticleSliderSizes>;
  variant?: ClassNameProp<'default'>;
}

export const ArticleSliderComponent: Component<ArticleSliderProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('colors', colors, 'primary');
  const sizeClassName = propClassName('size', size, ArticleSliderDefaultSize);
  const variantClassName = propClassName('variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const ArticleSlider = Object.assign(ArticleSliderComponent, {
  Slider: ArticleSliderSliderComponent,
  Arrow: ArticleSliderArrowComponent,
  Link: ArticleSliderLinkComponent,
  Navigation: Object.assign(ArticleSliderNavigationComponent, {
    Item: ArticleSliderNavigationItemComponent,
  }),
});

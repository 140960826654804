import { useClassName } from '@/styles/LoopAround';
import { ComponentPropsWithRef, ComponentWithRef } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { forwardRef } from 'react';
import { LoopAroundReelComponent } from './LoopAround.Reel';

export interface LoopAroundProps extends ComponentPropsWithRef<'div'> {}

const LoopAroundComponent: ComponentWithRef<HTMLDivElement, LoopAroundProps> = forwardRef(
  ({ children, ...props }, ref) => {
    const className = useClassName('', props);

    return (
      <div data-nosnippet {...mergeProps({ className }, props)} ref={ref}>
        {children}
      </div>
    );
  }
);

export const LoopAround = Object.assign(LoopAroundComponent, {
  Reel: LoopAroundReelComponent,
});

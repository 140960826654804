import c from '@/styles/ArticleTeaserBodytext';
import type { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { ArticleTeaserBodytextCategory } from './ArticleTeaserBodytext.Category';
import { ArticleTeaserBodytextDescription } from './ArticleTeaserBodytext.Description';
import { ArticleTeaserBodytextFooter } from './ArticleTeaserBodytext.Footer';
import { ArticleTeaserBodytextGroup } from './ArticleTeaserBodytext.Group';
import { ArticleTeaserBodytextHedline } from './ArticleTeaserBodytext.Headline';
import { ArticleTeaserBodytextImage } from './ArticleTeaserBodytext.Image';
import { ArticleTeaserBodytextLine } from './ArticleTeaserBodytext.Line';

export type ArticleTeaserBodytextVariant = 'first' | 'second' | 'third' | 'fourth';
export const DefaultArticleTeaserBodytextVariant: ArticleTeaserBodytextVariant = 'first';

export interface ArticleTeaserBodytextProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<ArticleTeaserBodytextVariant>;
  colors?: ClassNameProp<ArticleTeaserBodytextVariant>;
  size?: ClassNameProp<'default'>;
  className?: string;
}

const ArticleTeaserBodytextComponent: Component<ArticleTeaserBodytextProps> = ({
  className,
  variant,
  colors,
  size,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('variant', variant, DefaultArticleTeaserBodytextVariant);
  const colorsClassName = getPropStyles('colors', colors ?? variant, DefaultArticleTeaserBodytextVariant);
  const sizeClassName = getPropStyles('size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const ArticleTeaserBodytext = Object.assign(ArticleTeaserBodytextComponent, {
  Headline: ArticleTeaserBodytextHedline,
  Line: ArticleTeaserBodytextLine,
  Category: ArticleTeaserBodytextCategory,
  Description: ArticleTeaserBodytextDescription,
  Footer: ArticleTeaserBodytextFooter,
  Group: ArticleTeaserBodytextGroup,
  Image: ArticleTeaserBodytextImage,
});

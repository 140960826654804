/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneAccordion as ResolvedStandaloneAccordion } from 'base/components/standalone/Accordion';

export const Accordion = ResolvedStandaloneAccordion;

export type AccordionProps = PropsFromComponent<typeof Accordion>;

export const AccordionWith = (extras: DynamicStandaloneExtras): typeof Accordion => {
    return function Accordion(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Accordion');
        return <ResolvedStandaloneAccordion {...mergeProps({ options: { theme } }, props)} />;
    }
}
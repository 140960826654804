/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneArticleDate as ResolvedStandaloneArticleDate } from 'base/components/standalone/ArticleDate';

export const ArticleDate = ResolvedStandaloneArticleDate;

export type ArticleDateProps = PropsFromComponent<typeof ArticleDate>;

export const ArticleDateWith = (extras: DynamicStandaloneExtras): typeof ArticleDate => {
    return function ArticleDate(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleDate');
        return <ResolvedStandaloneArticleDate {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { componentClassName } from '@/styles/BreakingNews';

import { ClassNameProp, ClassNameProps, Component, ComponentProps, ThemeProp } from '@/types/component';
import { ComponentContextFactory } from 'lib/contexts/ComponentContextFactory';
import mergeProps from 'lib/utils/mergeProps';
import { BreakingNewsItemComponent } from './BreakingNews.Item';
import { BreakingNewsItemBlipComponent } from './BreakingNews.Item.Blip';
import { BreakingNewsItemHeadlineComponent } from './BreakingNews.Item.Headline';
import { BreakingNewsItemIconComponent } from './BreakingNews.Item.Icon';
import { BreakingNewsItemLabelComponent } from './BreakingNews.Item.Label';

export interface BreakingNewsProps extends ComponentProps<'section'> {
  variant?: ClassNameProp<'default' | 'alternative'>;
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
}

export const {
  Context: BreakingNewsContext,
  Provider: BreakingNewsProvider,
  useComponentContext: useBreakingNewsContext,
} = ComponentContextFactory<Pick<BreakingNewsProps, keyof ClassNameProps | keyof ThemeProp>>();

const BreakingNewsComponent: Component<BreakingNewsProps> = ({ variant, size, colors, theme, children, ...props }) => {
  const className = componentClassName('', { colors, size, variant }, { theme });

  return (
    <BreakingNewsProvider value={{ colors: colors ?? variant, size: size ?? variant, variant, theme }}>
      <section {...mergeProps({ className }, props)}>{children}</section>
    </BreakingNewsProvider>
  );
};

export const BreakingNews = Object.assign(BreakingNewsComponent, {
  Item: Object.assign(BreakingNewsItemComponent, {
    Blip: BreakingNewsItemBlipComponent,
    Icon: BreakingNewsItemIconComponent,
    Headline: BreakingNewsItemHeadlineComponent,
    Label: BreakingNewsItemLabelComponent,
  }),
});

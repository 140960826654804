/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTagsIndex as ResolvedStandaloneTagsIndex } from 'base/components/standalone/TagsIndex';

export const TagsIndex = ResolvedStandaloneTagsIndex;

export type TagsIndexProps = PropsFromComponent<typeof TagsIndex>;

export const TagsIndexWith = (extras: DynamicStandaloneExtras): typeof TagsIndex => {
    return function TagsIndex(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TagsIndex');
        return <ResolvedStandaloneTagsIndex {...mergeProps({ options: { theme } }, props)} />;
    }
}
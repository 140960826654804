import c from '@/styles/MostRead';
import { ClassNameProp, ComponentPropsWithRef, ComponentWithRef } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import React from 'react';

export interface MostReadSlideGroupProps extends ComponentPropsWithRef<'div'> {
  variant?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
}

export const MostReadSlideGroupComponent: ComponentWithRef<HTMLDivElement, MostReadSlideGroupProps> = React.forwardRef(
  ({ children, className, colors, size, variant, ...props }, ref) => {
    const getPropStyles = getPropStylesFactory(c);

    const colorsClassName = getPropStyles('slide_group_colors', colors, 'default');
    const variantClassName = getPropStyles('slide_group_variant', variant, 'default');
    const sizeClassName = getPropStyles('slide_group_size', size, 'default');

    const componentClassName = cn(variantClassName, className, colorsClassName, sizeClassName);

    return (
      <div className={componentClassName} {...props} {...{ ref }}>
        {children}
      </div>
    );
  }
);

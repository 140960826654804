import { useClassName } from '@/styles/TagsIndex';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type TagsIndexTagsProps = ComponentProps<'div'>;

export const TagsIndexTagsComponent: Component<TagsIndexTagsProps> = ({ children, ...props }) => {
  const className = useClassName('tags', props);

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

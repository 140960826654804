import c from '@/styles/TextArea';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { TextAreaStatuses } from '.';

export interface TextAreaCaptionProps extends ComponentProps<'span'> {
  variant?: ClassNameProp<'default'>;
  status?: ClassNameProp<TextAreaStatuses>;
}

export const TextAreaCaptionComponent: Component<TextAreaCaptionProps> = ({
  className,
  variant,
  status,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('caption_variant', variant, 'default');
  const statusClassName = getPropStyles('caption_status', status, 'default');
  const componentClassName = cn(variantClassName, statusClassName, className);

  return (
    <span className={componentClassName} {...props}>
      {children}
    </span>
  );
};

import { Adunit } from '@/components/Adunit';
import { Link } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getAdUnit } from 'lib/data/compute/ads/helpers';
import { Fragment, ReactNode } from 'react';
import { InstagramArticlesList, InstagramArticlesListProps } from './InstagramArticlesList';
import { InstagramArticlesListArticleProps } from './InstagramArticlesList.Article';
import { InstagramArticlesListArticleGroupProps } from './InstagramArticlesList.Article.Group';
import { InstagramArticlesListArticleHeadlineProps } from './InstagramArticlesList.Article.Headline';
import { InstagramArticlesListArticleImageProps } from './InstagramArticlesList.Article.Image';
import { InstagramArticlesListDescriptionProps } from './InstagramArticlesList.Description';
import { InstagramArticlesListHeadlineProps } from './InstagramArticlesList.Headline';

export interface InstagramArticle {
  type?: string;
  status?: string;
  title?: string;
  url?: string;
  instagramTimestamp?: string;
  instagramTitle?: string;
  published?: string;
  lab_site_id?: string;
  image?: string;
}

export interface StandaloneInstagramArticlesListProps extends StandaloneComponentProps {
  articles?: InstagramArticle[];
  headline?: ReactNode;
  adsUniqueId?: string;
  description?: ReactNode;
  options?: InstagramArticlesListProps & {
    $headline?: InstagramArticlesListHeadlineProps;
    $description?: InstagramArticlesListDescriptionProps;
    $article?: InstagramArticlesListArticleProps & {
      $headline?: InstagramArticlesListArticleHeadlineProps;
      $group?: InstagramArticlesListArticleGroupProps;
      $image?: InstagramArticlesListArticleImageProps;
    };
  };
}

export const StandaloneInstagramArticlesList: StandaloneComponent<StandaloneInstagramArticlesListProps> = ({
  articles,
  headline,
  description,
  options,
  adsUniqueId = '',
  ...props
}) => {
  const {
    $headline: headlineProps,
    $description: descriptionProps,
    $article: articleProps,
    ...baseProps
  } = options ?? {};
  const {
    $headline: articleHeadlineProps,
    $group: articleGroupProps,
    $image: articleImageProps,
    ...baseArticleProps
  } = articleProps ?? {};

  const renderAdunit = (adIndex: number) => {
    return (
      <Adunit
        data={getAdUnit('responsive', adIndex, adsUniqueId)?.data}
        title={'Annons'}
        options={{ className: 'mb-3' }}
      />
    );
  };

  const articlesPerGroup = 12;

  const resolveArticlesGroupList = (articles: InstagramArticle[] = [], articlesPerGroup: number) => {
    const instagramArticleList: Array<InstagramArticle[]> = [];

    let noToSlice = 3;

    while (articles.length > 0) {
      instagramArticleList.push(articles.slice(0, noToSlice));
      articles = articles.slice(noToSlice);
      noToSlice = articlesPerGroup;
    }
    return instagramArticleList;
  };

  const articlesGroupList: Array<InstagramArticle[]> = resolveArticlesGroupList(articles, articlesPerGroup);
  const renderArticles = (articles: InstagramArticle[] = []) => (
    <InstagramArticlesList.Article.Group {...articleGroupProps}>
      {articles &&
        articles?.length > 0 &&
        articles.map((article, index) => (
          <article key={index} className="cts-impression-group">
            <Link
              href={article.url}
              options={{
                className: 'cts-impression-item',
              }}
              data-cts-path={article.url}
              data-cts-label={article.title}
              data-cts-id={article.url?.split('/').at(-1)}
              data-cts-creative="article-teaser standard"
              content={
                <InstagramArticlesList.Article {...baseArticleProps}>
                  <InstagramArticlesList.Article.Image
                    alt={article.instagramTitle || article.title || 'Instagram artikel'}
                    imageId={article.image}
                    {...articleImageProps}
                  />
                  <InstagramArticlesList.Article.Headline {...articleHeadlineProps}>
                    {article.instagramTitle || article.title}
                  </InstagramArticlesList.Article.Headline>
                </InstagramArticlesList.Article>
              }
            />
          </article>
        ))}
    </InstagramArticlesList.Article.Group>
  );

  return (
    <InstagramArticlesList {...baseProps} {...props}>
      <InstagramArticlesList.Headline {...headlineProps}>{headline}</InstagramArticlesList.Headline>
      <InstagramArticlesList.Description {...descriptionProps}>{description}</InstagramArticlesList.Description>

      {articles &&
        articlesGroupList &&
        articlesGroupList.map((articlesGroup, groupIndex) => (
          <Fragment key={groupIndex}>
            {articlesGroup && renderArticles(articlesGroup)}
            {(articlesGroup.length > articlesPerGroup - 1 || groupIndex < 1) && renderAdunit(groupIndex + 1)}
          </Fragment>
        ))}
    </InstagramArticlesList>
  );
};

import { propClassName } from '@/styles/Twitter';
import { ClassNameProp, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';

import { Markup, MarkupProps } from '@/components/Markup';
import { Component } from '@/types/component';
import { useEffect } from 'react';

export interface TwitterProps extends ComponentProps<'section'> {
  colors?: ClassNameProp;
  markup?: MarkupProps['markup'];
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

const TwitterComponent: Component<TwitterProps> = ({ className, colors, markup, size, variant, ...props }) => {
  const colorsClassName = propClassName('colors', colors);
  const sizeClassName = propClassName('size', size);
  const variantClassName = propClassName('variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute('src', 'https://platform.x.com/widgets.js');
    s.setAttribute('async', 'true');
    document.head.appendChild(s);
  }, []);

  return <Markup options={{ className: componentClassName }} {...{ markup }} {...props} />;
};

export const Twitter = Object.assign(TwitterComponent, {});

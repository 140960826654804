import { default as BaseArticleHeaderTheme } from 'base/components/ArticleHeader/theme';

import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const Tag = tw.theme({
  extend: TagTheme,
  base: '',
  variants: {
    colors: {
      text: ``,
    },
    size: {
      text: '',
    },
    variant: {
      text: 'capitalize',
    },
  },
});

const ArticleHeader = tw.theme({
  extend: BaseArticleHeaderTheme,
  slots: {
    ...BaseArticleHeaderTheme.slots,
  },
  variants: {
    variant: {
      first: {
        base: `mx-auto w-full gap-x-3 gap-y-0 lg:max-w-col-lg-8`,
        bylines: `mt-2 gap-x-3`,
        category: ``,
        description: `text-preamble-sm mt-2 w-full sm:text-preamble-md`,
        headline: `mb-2 text-headline-lg sm:text-headline-2xl`,
        media: `mb-2 mt-4 [&_img]:aspect-[40/21] [&_img]:object-cover [&_picture]:-mx-3 [&_picture]:w-[calc(100%_+_24px)] sm:[&_picture]:-mx-4.5 sm:[&_picture]:w-[calc(100%_+_36px)] md:[&_picture]:mx-0 md:[&_picture]:w-full`,
        tags: `after:bg-primary-700 relative order-1 col-span-2 flex items-center after:block after:h-2.5 after:flex-grow`,
        date: `text-ui-md text-gray-700`,
      },
    },
  },
  defaultVariants: {
    variant: 'first',
  },
});

export default Object.assign(ArticleHeader, { Tag });

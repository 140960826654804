import { Article } from '@/components/Article';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Content, Data, Meta } from '@/types/content';
import { SecondNativeArticleCampaign } from '@/types/secondNativeArticleCampaign';
import { useAppState } from 'lib/hooks/useAppState';
import { useIntersectionObserver } from 'lib/hooks/useIntersectionObserver';
import { useSeenArticleCampaignsLocalStorage } from 'lib/hooks/useSeenArticleCampaignsLocalStorage';
import { useRouter } from 'next/router';
import { useCallback, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { updateAllerGaData } from './helpers';

const registerPageView = () => {
  window.cts?.send?.pageview?.();
};

export interface StandaloneSecondNativeArticle extends StandaloneComponentProps {
  meta: Meta;
  data: Data;
  descendants: Content[];
  campaign: SecondNativeArticleCampaign;
}

export const StandaloneSecondNativeArticle: StandaloneComponent<StandaloneSecondNativeArticle> = ({
  meta,
  data,
  descendants,
  campaign,
}) => {
  const [firstArticleTrackingData, setFirstArticleTrackingData] = useState<{
    trackingData: Record<string, string> | null;
    allerGaData: Record<string, string> | null;
  }>({
    trackingData: null,
    allerGaData: null,
  });
  const [isCampaignRegistered, setIsCampaignRegistered] = useState(false);
  const [_, registerCampaignView] = useSeenArticleCampaignsLocalStorage();
  const [{ publishedUrl }] = useAppState();
  const articleRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const secondNativeTrackingData = {
    allerGaData: data?.analyticsData?.allerGaData,
    trackingData: data?.analyticsData?.trackingData,
  };

  const getSecondNativeUrl = () => {
    const searchParams = new URLSearchParams({
      second_article: String(true),
    }).toString();

    return data.publishedUrl + '?' + searchParams;
  };

  useEffectOnce(() => {
    setFirstArticleTrackingData({
      allerGaData: window.aller_ga || null,
      trackingData: window.cts?.metadata || null,
    });
  });

  const callback: IntersectionObserverCallback = useCallback(
    (entries) => {
      entries.forEach(async (entry) => {
        const isArticleVisible = entry.isIntersecting;
        const isArticleFullyRead = entry.boundingClientRect.y < 0;
        const isArticleActive = isArticleVisible || isArticleFullyRead;
        const isPlayerFloating = window.jwplayer?.()?.getFloating();

        const resolvedUrl = isArticleActive ? getSecondNativeUrl() : publishedUrl;
        const resolvedTrackingData = isArticleActive ? secondNativeTrackingData : firstArticleTrackingData;

        if (isArticleVisible && isPlayerFloating) {
          window.jwplayer?.()?.setFloating(false);
        }

        if (isArticleActive && !isCampaignRegistered) {
          setIsCampaignRegistered(true);
          registerCampaignView(campaign.campaignId);
          updateAllerGaData(secondNativeTrackingData.allerGaData, secondNativeTrackingData.trackingData, true);
          registerPageView();
        } else {
          updateAllerGaData(resolvedTrackingData.allerGaData, resolvedTrackingData.trackingData, isArticleActive);
        }

        if (resolvedUrl && router.asPath !== resolvedUrl) {
          await router.push(resolvedUrl, undefined, { shallow: true, scroll: false });
        }
      });
    },
    [
      publishedUrl,
      router,
      campaign.campaignId,
      registerCampaignView,
      isCampaignRegistered,
      data,
      firstArticleTrackingData,
    ],
  );

  useIntersectionObserver([articleRef], callback, { threshold: [0] });

  const nativeAdLabel = meta?.nativeAdLabel || '';
  const affiliateLabel = data?.affiliateLabel || '';

  const disclosureLabels = [];

  if (meta?.isNativeAd && nativeAdLabel) disclosureLabels.push(nativeAdLabel);
  if (data?.hasAffiliate && affiliateLabel) disclosureLabels.push(affiliateLabel);

  return (
    <div ref={articleRef}>
      <Article
        articleDisclosureLabels={disclosureLabels}
        descendants={descendants}
        options={{ 'data-cts-scroll-depth-item': 'second-native-article' }}
        hide={{
          trendingMenu: true,
          breakingNews: true,
          recommendedArticles: true,
          badge: true,
        }}
      />
    </div>
  );
};

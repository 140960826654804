import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import logger from '@/utils/logger';
import { isString } from 'typesafe-utils';
import { Markup } from './Markup';
import { getScriptElements } from './utils/getScriptTags';
import { splitMarkup } from './utils/splitMarkup';

export interface StandaloneMarkupProps extends StandaloneComponentProps<typeof Markup> {
  markup?: string;
}

export const StandaloneMarkup: StandaloneComponent<StandaloneMarkupProps> = ({ markup, ...props }) => {
  const contentIndex = props['data-content-index'];

  if (!markup || !isString(markup)) {
    logger.warn('Invalid markup', markup);
    return null;
  }

  const [html, scripts] = splitMarkup(markup);

  return (
    <>
      <Markup {...props}>{html}</Markup>
      {getScriptElements(contentIndex, scripts)}
    </>
  );
};

import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { stripHtml } from 'lib/utils/stripHtml';
import { Children } from 'react';
import { isString } from 'typesafe-utils';
import { useJWPlayer } from './JWPlayerContext';

export interface VideoArticleDescriptionProps extends ComponentProps<'figcaption'> {
  mediaId?: string;
}

export const VideoArticleDescriptionComponent: Component<VideoArticleDescriptionProps> = ({
  children,
  mediaId,
  ...props
}) => {
  const className = useClassName('videoDescription', props);

  const { currentMedia } = useJWPlayer();

  const videoHasChanged = currentMedia?.mediaid && currentMedia?.mediaid !== mediaId;

  return (
    <figcaption {...mergeProps({ className }, props)} itemProp="caption">
      {videoHasChanged
        ? stripHtml(currentMedia?.description)
        : Children.map(children, (child) => {
            return isString(child) ? stripHtml(child) : child;
          })}
    </figcaption>
  );
};

import c from '@/styles/ArticleTeaserBodytext';
import type { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { ArticleTeaserBodytextVariant, DefaultArticleTeaserBodytextVariant } from './ArticleTeaserBodytext';

export interface ArticleTeaserBodytextLineProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<ArticleTeaserBodytextVariant>;
  colors?: ClassNameProp<ArticleTeaserBodytextVariant>;
  size?: ClassNameProp<'default'>;
}

export const ArticleTeaserBodytextLine: Component<ArticleTeaserBodytextLineProps> = ({
  className,
  colors,
  size,
  variant,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('line_variant', variant, DefaultArticleTeaserBodytextVariant);
  const colorsClassName = getPropStyles('line_colors', colors ?? variant, DefaultArticleTeaserBodytextVariant);
  const sizeClassName = getPropStyles('line_size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

import { OptionalLink } from '@/components/OptionalLink';
import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export interface VideoArticleHeaderLinkProps extends ComponentProps<'span'> {
  videoPageLink?: string;
  className?: string;
  ctsLabel?: string;
  mediaId?: string;
}

export const VideoArticleHeaderLinkComponent: Component<VideoArticleHeaderLinkProps> = ({
  videoPageLink,
  ctsLabel,
  mediaId,
  children,
  ...props
}) => {
  const className = useClassName('header_link', props, { className: videoPageLink ? 'cts-impression-item' : '' });

  return (
    <OptionalLink
      href={videoPageLink}
      data-cts-label={ctsLabel}
      data-cts-name={ctsLabel}
      data-cts-id={mediaId}
      data-cts-creative={ctsLabel}
      aria-label="Gå till video"
      content={<span {...mergeProps({ className }, props)}>{children}</span>}
    />
  );
};

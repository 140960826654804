/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "image": {
    "group_variant_default": "md:row-start-1 md:row-end-5",
    "group_variant_alternative": "",
    "caption_byline_variant_default": "hidden",
    "caption_text_variant_default": "hidden"
  },
  "counter_variant_default": "",
  "counter_variant_alternative": "",
  "counter_colors_default": "",
  "counter_size_default": "text-body-sm",
  "counter_size_alternative": "text-body-sm",
  "subtitle_variant_default": "",
  "subtitle_variant_alternative": "",
  "subtitle_colors_default": "",
  "subtitle_size_default": "text-body-xs",
  "subtitle_size_alternative": "text-body-xs",
  "description_variant_default": "text-center",
  "description_variant_alternative": "text-center",
  "description_colors_default": "",
  "description_size_default": "text-body-sm md:px-10 mb-2",
  "description_size_alternative": "text-body-sm max-w-bodytext mb-2",
  "headline_variant_default": "text-center",
  "headline_variant_alternative": "text-center",
  "headline_colors_default": "",
  "headline_size_default": "text-headline-md md:text-headline-lg",
  "headline_size_alternative": "text-headline-md md:text-headline-lg",
  "group_variant_default": "flex flex-col items-center justify-center md:justify-end md:col-start-2 md:row-start-2",
  "group_variant_alternative": "flex flex-col items-center md:col-span-2",
  "group_colors_default": "",
  "group_size_default": "gap-2.5 md:gap-3",
  "group_size_alternative": "gap-2.5 md:gap-4",
  "product_vendor_variant_default": "text-center",
  "product_vendor_variant_alternative": "text-center",
  "product_vendor_colors_default": "",
  "product_vendor_size_default": "text-body-xs",
  "product_vendor_size_alternative": "text-body-xs",
  "product_headline_variant_default": "text-center",
  "product_headline_variant_alternative": "text-center",
  "product_headline_colors_default": "",
  "product_headline_size_default": "text-preamble-md",
  "product_headline_size_alternative": "text-preamble-md",
  "product_caption_variant_default": "block text-center underline",
  "product_caption_variant_alternative": "block text-center underline",
  "product_caption_colors_default": "",
  "product_caption_size_default": "text-headline-xs md:text-headline-sm",
  "product_caption_size_alternative": "text-headline-xs md:text-headline-sm",
  "product_variant_default": "flex flex-col md:col-start-2 md:row-start-3",
  "product_variant_alternative": "flex flex-col md:row-start-3",
  "product_colors_default": "",
  "product_size_default": "gap-2.5 md:gap-3",
  "product_size_alternative": "gap-2.5 md:gap-3 mb-3",
  "variant_default": "grid md:grid-cols-2 md:grid-rows-[1fr_auto_auto_1fr]",
  "variant_alternative": "grid md:grid-cols-2",
  "colors_default": "",
  "size_default": "gap-2.5",
  "size_alternative": "gap-2.5 md:gap-y-4 md:gap-x-6"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "AffiliateItem");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
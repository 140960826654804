import c from '@/styles/RadioButton';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface RadioButtonLabelProps extends ComponentProps<'label'> {
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export const RadioButtonLabelComponent: Component<RadioButtonLabelProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('label_colors', colors, 'default');
  const sizeClassName = getPropStyles('label_size', size, 'default');
  const variantClassName = getPropStyles('label_variant', variant, 'default');
  const componentClassName = cn('cursor-pointer', colorsClassName, sizeClassName, variantClassName, className);

  return (
    <label className={componentClassName} {...props}>
      {children}
    </label>
  );
};

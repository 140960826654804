import { useClassName } from '@/styles/AffiliateItem';
import { ClassNameProp, PropsFromComponent, PropsFromSubcomponents } from '@/types/component';
import { merge } from '@/utils/merge';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import { AffiliateProductVariants } from '.';
import { AffiliateItemProductImageComponent } from './AffiliateItem.Product.Image';

type Extras = {
  size?: ClassNameProp<AffiliateProductVariants>;
  variant?: ClassNameProp<AffiliateProductVariants>;
};

export type AffiliateItemProps = PropsFromComponent<typeof AffiliateItem>;
export type AffiliateItemSubcomponentProps = PropsFromSubcomponents<typeof AffiliateItem>;

const $ = GenericComponentFactory<Extras>({ useClassName });

const Base = $({});
const Headline = $({ as: 'h2', styles: { key: 'headline' } });
const Description = $({ as: 'p', styles: { key: 'description' } });
const Counter = $({ as: 'span', styles: { key: 'counter' } });
const Subtitle = $({ as: 'span', styles: { key: 'subtitle' } });
const Group = $({ styles: { key: 'group' } });
const Product = $({ styles: { key: 'product' } });
const ProductHeadline = $({ as: 'h5', styles: { key: 'product_headline' } });
const ProductCaption = $({ as: 'span', styles: { key: 'product_caption' } });
const ProductVendor = $({ as: 'span', styles: { key: 'product_vendor' } });

export const AffiliateItem = merge(Base, {
  Headline,
  Description,
  Counter,
  Subtitle,
  Group,
  Product: merge(Product, {
    Image: AffiliateItemProductImageComponent,
    Headline: ProductHeadline,
    Caption: ProductCaption,
    Vendor: ProductVendor,
  }),
});

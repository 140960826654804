import { ArticleTeaserDefaultProps } from '@/components/ArticleTeaser/Default';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { isClient } from 'lib/utils/isClient';
import mergeProps from 'lib/utils/mergeProps';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { RecommendedArticles, RecommendedArticlesProps } from './RecommendedArticles';
import { RecommendedArticlesHeadlineProps } from './RecommendedArticles.Headline';
import { RecommendedArticlesListProps } from './RecommendedArticles.List';
import { RecommendedArticlesListItemProps } from './RecommendedArticles.List.Item';
import { RecommendedArticlesListItemTeaserProps } from './RecommendedArticles.List.Item.Teaser';
import { brandConfig } from './config';

export interface StandaloneRecommendedArticlesProps extends StandaloneComponentProps {
  articles?: ArticleTeaserDefaultProps[];
  articlesCount?: number;
  headline?: ReactNode;
  options?: RecommendedArticlesProps & {
    $headline?: RecommendedArticlesHeadlineProps;
    $list?: RecommendedArticlesListProps & {
      $item?: RecommendedArticlesListItemProps & {
        $teaser?: RecommendedArticlesListItemTeaserProps['$standalone']['options'];
      };
    };
  };
}

export const StandaloneRecommendedArticles: StandaloneComponent<StandaloneRecommendedArticlesProps> = ({
  articles,
  articlesCount,
  headline,
  options,
  ...props
}) => {
  if (!articles || articles.length === 0) {
    return null;
  }

  const { $headline: headlineProps, $list, ...baseProps } = options ?? {};
  const { $item, ...listProps } = $list ?? {};
  const { $teaser: listItemTeaserProps, ...listItemProps } = $item ?? {};

  const MIN_ARTICLES_COUNT = 4;
  const ARTICLES_COUNT_OR_MIN = articlesCount ?? MIN_ARTICLES_COUNT;

  return (
    <>
      <RecommendedArticles {...baseProps} {...props}>
        <RecommendedArticles.Headline {...headlineProps}>
          {headline ?? 'Rekommenderad läsning'}
        </RecommendedArticles.Headline>

        <RecommendedArticles.List {...listProps}>
          {articles.slice(0, ARTICLES_COUNT_OR_MIN).map((article, index) => (
            <RecommendedArticles.List.Item key={index} {...listItemProps}>
              <RecommendedArticles.List.Item.Teaser
                $standalone={mergeProps({ options: { ...listItemTeaserProps } }, article)}
              />
            </RecommendedArticles.List.Item>
          ))}
        </RecommendedArticles.List>
      </RecommendedArticles>

      {isClient() &&
        brandConfig.showMoreBelowArticle &&
        articles.length > ARTICLES_COUNT_OR_MIN &&
        createPortal(
          <RecommendedArticles {...baseProps} {...props}>
            <RecommendedArticles.Headline {...headlineProps}>{'Mer för dig'}</RecommendedArticles.Headline>

            <RecommendedArticles.List {...listProps}>
              {articles.slice(ARTICLES_COUNT_OR_MIN).map((article, index) => (
                <RecommendedArticles.List.Item key={index} {...listItemProps}>
                  <RecommendedArticles.List.Item.Teaser
                    $standalone={mergeProps({ options: { ...listItemTeaserProps } }, article)}
                  />
                </RecommendedArticles.List.Item>
              ))}
            </RecommendedArticles.List>
          </RecommendedArticles>,
          document.querySelector('#more-recommended-articles-portal') as Element,
        )}
    </>
  );
};

export type RecommendedArticlesData = Pick<
  ArticleTeaserDefaultProps,
  'caption' | 'headline' | 'description' | 'image' | 'links'
>[];

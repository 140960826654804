/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneNoInfinityTeaserBoxTitle as ResolvedStandaloneNoInfinityTeaserBoxTitle } from 'base/components/standalone/NoInfinityTeaserBoxTitle';

export const NoInfinityTeaserBoxTitle = ResolvedStandaloneNoInfinityTeaserBoxTitle;

export type NoInfinityTeaserBoxTitleProps = PropsFromComponent<typeof NoInfinityTeaserBoxTitle>;

export const NoInfinityTeaserBoxTitleWith = (extras: DynamicStandaloneExtras): typeof NoInfinityTeaserBoxTitle => {
    return function NoInfinityTeaserBoxTitle(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'NoInfinityTeaserBoxTitle');
        return <ResolvedStandaloneNoInfinityTeaserBoxTitle {...mergeProps({ options: { theme } }, props)} />;
    }
}
import c from '@/styles/Youtube';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface YoutubeProps extends ComponentProps<'section'> {
  vidID: string;
  variant?: ClassNameProp<'default'>;
}

const YoutubeComponent: Component<YoutubeProps> = ({ vidID, variant, className, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('variant', variant, 'default');
  const componentClassName = cn(variantClassName, className);

  const iframeVariantClassName = getPropStyles('iframe_variant', variant, 'default');
  const iframeClassName = cn(iframeVariantClassName);

  const videoSrc = `https://www.youtube.com/embed/${vidID}`;

  return (
    <section className={componentClassName} {...props}>
      <iframe
        className={iframeClassName}
        src={videoSrc}
        frameBorder="0"
        allowFullScreen
        sandbox="allow-scripts allow-same-origin"
        loading="lazy"
      />
    </section>
  );
};

export const Youtube = Object.assign(YoutubeComponent, {});

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTextMultiline as ResolvedStandaloneTextMultiline } from 'base/components/standalone/TextMultiline';

export const TextMultiline = ResolvedStandaloneTextMultiline;

export type TextMultilineProps = PropsFromComponent<typeof TextMultiline>;

export const TextMultilineWith = (extras: DynamicStandaloneExtras): typeof TextMultiline => {
    return function TextMultiline(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TextMultiline');
        return <ResolvedStandaloneTextMultiline {...mergeProps({ options: { theme } }, props)} />;
    }
}
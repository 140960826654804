import { componentClassName, propClassName } from '@/styles/ArticleFooter';
import { Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import mergeProps from 'lib/utils/mergeProps';

export interface ArticleFooterTagsProps extends ComponentProps<'div'> {
  align?: 'left' | 'center';
}

export const ArticleFooterTags: Component<ArticleFooterTagsProps> = ({ variant, size, colors, align, ...props }) => {
  const alignClassName = propClassName('tags_align', align, 'left');
  const className = cn(componentClassName('tags', { colors, size, variant }), alignClassName);

  return <div {...mergeProps({ className }, props)} />;
};

import { FactBox } from '@/components/FactBox';
import { LabradorComponent } from '@/types/component';

import { renderContentComponent } from 'modules/dynamic/components';

export const LabradorFactBox: LabradorComponent = ({ meta, data, descendants }) => {
  const images = descendants.map(renderContentComponent);

  return (
    <FactBox headline={data.title} markup={data.bodytext} data-content-index={meta.contentIndex} elements={images} />
  );
};

import { Icon } from '@/components/Icon';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { IconProps } from 'base/components/Icon/Icon';
import { ReactNode, useId } from 'react';
import { TextArea, TextAreaProps } from './TextArea';
import { TextAreaCaptionProps } from './TextArea.Caption';
import { TextAreaCaptionGroupProps } from './TextArea.Caption.Group';
import { TextAreaFieldProps } from './TextArea.Field';
import { TextAreaFieldGroupProps } from './TextArea.Field.Group';
import { TextAreaLabelProps } from './TextArea.Label';

export type TextAreaStatuses = 'default' | 'error';
export type TextAreaVariants = 'default' | 'floating';

export interface StandaloneTextAreaProps extends StandaloneComponentProps {
  label?: ReactNode;
  captionText?: ReactNode;
  icon?: IconProps['name'] | null;
  iconSize?: IconProps['size'];
  options?: TextAreaProps & {
    $label?: TextAreaLabelProps;
    $field?: TextAreaFieldProps;
    $caption?: TextAreaCaptionProps;
    $captionGroup?: TextAreaCaptionGroupProps;
    $fieldGroup?: TextAreaFieldGroupProps;
  };
}

export const StandaloneTextArea: StandaloneComponent<StandaloneTextAreaProps> = ({
  label,
  captionText,
  icon,
  iconSize = 'medium',
  options,
}) => {
  const {
    $label: labelProps,
    $field: fieldProps,
    $caption: captionProps,
    $captionGroup: captionGroupProps,
    $fieldGroup: fieldGroupProps,
    ...baseProps
  } = options ?? {};

  const id = useId();

  return (
    <TextArea {...baseProps}>
      <TextArea.Field.Group {...fieldGroupProps}>
        <TextArea.Field id={id} placeholder=" " {...fieldProps} />
        <TextArea.Label htmlFor={id} {...labelProps}>
          {label}
        </TextArea.Label>
      </TextArea.Field.Group>
      <TextArea.Caption.Group {...captionGroupProps}>
        {icon && <Icon name={icon} options={{ size: iconSize }} />}
        <TextArea.Caption {...captionProps}>{captionText}</TextArea.Caption>
      </TextArea.Caption.Group>
    </TextArea>
  );
};

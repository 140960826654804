/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_primary": "bg-gray-100 text-gray-600 wings-gray-100",
  "size_default": "text-ui-md",
  "variant_default": "sticky z-article-disclosure text-center transition-transform duration-500 ease-in-out wings"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ArticleDisclosureLabel");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
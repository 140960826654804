import { ClassNameProvider } from '@/styles/TagsIndex';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { isSwedishLetter, ucfirst } from 'lib/utils';
import { mergeOptions } from 'lib/utils/mergeProps';
import { TagsIndex } from './TagsIndex';

interface TagGroup {
  letter: string;
  tags?: string[];
}

export interface StandaloneTagsIndexProps extends StandaloneComponentProps<typeof TagsIndex> {
  headline?: string;
  tags?: string[];
}

const other = 'Övrigt';

export const StandaloneTagsIndex: StandaloneComponent<StandaloneTagsIndexProps> = ({
  headline,
  tags,
  options,
  ...props
}) => {
  const { $headline, $letter, $tag, $tags, ...$base } = options ?? {};

  const tagsObject =
    tags &&
    tags.reduce((acc: { [key: string]: string[] }, item: string) => {
      const letter: string = item.charAt(0);

      if (!letter.match(/[a-zåäö]/i)) {
        return {
          ...acc,
          [other]: [...(acc[other] ?? []), item],
        };
      }

      return {
        ...acc,
        [letter]: [...(acc[letter] ?? []), item],
      };
    }, {});

  const tagsArray: TagGroup[] = tagsObject
    ? Object.keys(tagsObject).map((key) => ({
        letter: key,
        tags: (tagsObject as { [x: string]: string[] })[key].sort(),
      }))
    : [];

  tagsArray.sort((a, b) => {
    if (a.letter === other) {
      return 1;
    }

    if (b.letter === other) {
      return -1;
    }

    if (isSwedishLetter(a.letter) && isSwedishLetter(b.letter)) {
      return a.letter.localeCompare(b.letter);
    }

    return a.letter < b.letter ? -1 : a.letter > b.letter ? 1 : 0;
  });

  return (
    <ClassNameProvider value={{ ...$base, size: $base.size ?? $base.variant }}>
      <TagsIndex {...$base} {...props}>
        {headline && <TagsIndex.Headline {...$headline}>{headline}</TagsIndex.Headline>}
        {tagsArray &&
          tagsArray.map((tagGroup) => (
            <div key={tagGroup.letter}>
              <TagsIndex.Letter {...$letter}>{ucfirst(tagGroup.letter)}</TagsIndex.Letter>
              <TagsIndex.Tags {...$tags}>
                {tagGroup.tags?.map((tagItem, index) => (
                  <TagsIndex.Tag
                    key={index}
                    value={tagItem}
                    link={{ href: `/etikett/${tagItem}` }}
                    options={mergeOptions(
                      {
                        variant: 'text',
                      },
                      $tag,
                    )}
                  />
                ))}
              </TagsIndex.Tags>
            </div>
          ))}
      </TagsIndex>
    </ClassNameProvider>
  );
};

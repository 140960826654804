/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneBanner as ResolvedStandaloneBanner } from 'base/components/standalone/Banner';

export const Banner = ResolvedStandaloneBanner;

export type BannerProps = PropsFromComponent<typeof Banner>;

export const BannerWith = (extras: DynamicStandaloneExtras): typeof Banner => {
    return function Banner(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Banner');
        return <ResolvedStandaloneBanner {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { useClassName } from '@/styles/SaveArticleButton';
import { ComponentProps, ComponentWithRef } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import React from 'react';

export type SaveArticleButtonModalProps = ComponentProps<'dialog'>;

export const SaveArticleButtonModalComponent: ComponentWithRef<HTMLDialogElement, SaveArticleButtonModalProps> =
  React.forwardRef(({ children, ...props }, ref) => {
    const className = useClassName('modal', props);
    return (
      <dialog ref={ref} {...mergeProps({ className }, props)}>
        {children}
      </dialog>
    );
  });

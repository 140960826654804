/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneArticleSlider as ResolvedStandaloneArticleSlider } from 'base/components/standalone/ArticleSlider';

export const ArticleSlider = ResolvedStandaloneArticleSlider;

export type ArticleSliderProps = PropsFromComponent<typeof ArticleSlider>;

export const ArticleSliderWith = (extras: DynamicStandaloneExtras): typeof ArticleSlider => {
    return function ArticleSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleSlider');
        return <ResolvedStandaloneArticleSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}
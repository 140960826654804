import { useClassName } from '@/styles/JwVideoArticle';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { useJWPlayer } from './JWPlayerContext';
import formatMinutes from './utils/formatMinutes';

export interface VideoArticleVideoContainerNextVideoDurationProps extends ComponentProps<'p'> {
  mediaId?: string;
}

export const VideoArticleVideoContainerNextVideoDurationComponent: Component<
  VideoArticleVideoContainerNextVideoDurationProps
> = ({ mediaId, children, ...props }) => {
  const { currentMedia } = useJWPlayer();
  const className = useClassName('videoContainer_nextVideo_duration', props);

  const videoHasChanged = currentMedia?.mediaid && currentMedia?.mediaid !== mediaId;
  const videoDuration = formatMinutes(currentMedia?.duration as any);
  return (
    <>{videoDuration && <p {...mergeProps({ className }, props)}>{videoHasChanged ? videoDuration : children}</p>}</>
  );
};

import { propClassName } from '@/styles/SavedArticles';
import { cn } from 'lib/utils/cn';
import type { ClassNameProp, Component, ComponentProps } from 'types/component';

export interface SavedArticlesLineProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

export const SavedArticlesLineComponent: Component<SavedArticlesLineProps> = ({
  className,
  variant,
  colors,
  size,
  children,
  ...props
}) => {
  const variantClassName = propClassName('line_variant', variant, 'default');
  const colorsClassName = propClassName('line_colors', colors, 'default');
  const sizeClassName = propClassName('line_size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

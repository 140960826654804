import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import getConfig from 'modules/config';
import { ReactNode } from 'react';
import { isUndefined } from 'typesafe-utils';
import { NoInfinityTeaserBoxTitle } from './NoInfinityTeaserBoxTitle';

export interface StandaloneNoInfinityTeaserBoxTitleProps extends StandaloneComponentProps {
  content?: ReactNode;
}

export const StandaloneNoInfinityTeaserBoxTitle: StandaloneComponent<StandaloneNoInfinityTeaserBoxTitleProps> = ({
  content,
  ...props
}) => {
  const [{ type }] = useAppState();
  const title = getConfig(`infinityScroll.${type}.title`);

  if (isUndefined(content) && isUndefined(title)) {
    return null;
  }

  return <NoInfinityTeaserBoxTitle {...props}>{content || title}</NoInfinityTeaserBoxTitle>;
};

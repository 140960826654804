/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { SvenskdamStandaloneBreakingNews as ResolvedStandaloneBreakingNews } from 'sites/svenskdam/components/standalone/BreakingNews';

export const BreakingNews = ResolvedStandaloneBreakingNews;

export type BreakingNewsProps = PropsFromComponent<typeof BreakingNews>;

export const BreakingNewsWith = (extras: DynamicStandaloneExtras): typeof BreakingNews => {
    return function BreakingNews(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'BreakingNews');
        return <ResolvedStandaloneBreakingNews {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { useClassName } from '@/styles/TagsIndex';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type TagsIndexLetterProps = ComponentProps<'div'>;

export const TagsIndexLetterComponent: Component<TagsIndexLetterProps> = ({ children, ...props }) => {
  const className = useClassName('letter', props);

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

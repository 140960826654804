import { propClassName } from '@/styles/SavedArticles';
import { cn } from 'lib/utils/cn';
import type { ClassNameProp, Component, ComponentProps } from 'types/component';

export interface SavedArticlesArticleProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

export const SavedArticlesArticleComponent: Component<SavedArticlesArticleProps> = ({
  className,
  variant,
  colors,
  size,
  children,
  ...props
}) => {
  const variantClassName = propClassName('article_variant', variant, 'default');
  const colorsClassName = propClassName('article_colors', colors, 'default');
  const sizeClassName = propClassName('article_size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneYoutube as ResolvedStandaloneYoutube } from 'base/components/standalone/Youtube';

export const Youtube = ResolvedStandaloneYoutube;

export type YoutubeProps = PropsFromComponent<typeof Youtube>;

export const YoutubeWith = (extras: DynamicStandaloneExtras): typeof Youtube => {
    return function Youtube(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Youtube');
        return <ResolvedStandaloneYoutube {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { propClassName } from '@/styles/RecommendedArticles';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
export interface RecommendedArticlesListItemProps extends ComponentProps<'li'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

export const RecommendedArticlesListItemComponent: Component<RecommendedArticlesListItemProps> = ({
  variant,
  size,
  colors,
  children,
  className,
  ...props
}) => {
  const variantClassName = propClassName('list_item_variant', variant, 'default');
  const sizeClassName = propClassName('list_item_size', size, 'default');
  const colorsClassName = propClassName('list_item_colors', colors, 'default');

  const componentClassName = cn(variantClassName, sizeClassName, colorsClassName, className);

  return (
    <li className={componentClassName} {...props}>
      {children}
    </li>
  );
};

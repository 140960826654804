import c from '@/styles/Youplay';
import { Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface YouplayNotFoundTextProps extends ComponentProps<'p'> {
  text?: 'default';
}

export const YouplayNotFoundTextComponent: Component<YouplayNotFoundTextProps> = ({
  className,
  text,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('text_variant', text, 'default');
  const componentClassName = cn(variantClassName, className);
  return <p className={componentClassName}>{children}</p>;
};

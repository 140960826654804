/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneLoopAround as ResolvedStandaloneLoopAround } from 'base/components/standalone/LoopAround';

export const LoopAround = ResolvedStandaloneLoopAround;

export type LoopAroundProps = PropsFromComponent<typeof LoopAround>;

export const LoopAroundWith = (extras: DynamicStandaloneExtras): typeof LoopAround => {
    return function LoopAround(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'LoopAround');
        return <ResolvedStandaloneLoopAround {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { SliderSlide, type SliderSlideProps } from '@/components/Slider/Slide';
import { SliderSwiper, type SliderSwiperProps } from '@/components/Slider/Swiper';
import { componentTheme } from '@/styles/AffiliateGallery';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { useMemo } from 'react';
import { AffiliateGallery, AffiliateGallerySubcomponentProps } from './AffiliateGallery';

SliderSlide.displayName = 'SwiperSlide'; // Required for `Swiper` to work

export interface AffiliateGalleryProduct {
  id: number;
  link: string;
  image_link: string;
  price: string;
  title: string;
}
interface ExtendedOptions {
  $slide?: SliderSlideProps['options'];
  $swiper?: SliderSwiperProps['options'];
  $line?: AffiliateGallerySubcomponentProps['Disclaimer']['Line'];
}

export interface StandaloneAffiliateGalleryProps
  extends StandaloneComponentProps<typeof AffiliateGallery, ExtendedOptions> {
  disclaimer?: React.ReactNode;
  title?: string;
  products?: AffiliateGalleryProduct[];
}

export const StandaloneAffiliateGallery: StandaloneComponent<StandaloneAffiliateGalleryProps> = ({
  disclaimer,
  title,
  products,
  options,
  ...props
}) => {
  const { $swiper, $slide, $title, $disclaimer, $line, $price, ...$base } = { ...options };

  const memoSlides = useMemo(
    () =>
      products?.map(({ id, link, image_link, price, title }) => (
        <SliderSlide
          key={id}
          url={link}
          title={
            <>
              <AffiliateGallery.Title {...$title}>{title}</AffiliateGallery.Title>
              <AffiliateGallery.Price {...$price}>{price}</AffiliateGallery.Price>
            </>
          }
          imageSrc={image_link}
          options={mergeOptions({ className: 'cts-impression-item', theme: componentTheme('slide') }, $slide)}
          data-cts-object="affiliate-carousel"
          data-cts-object-name={title}
          data-cts-object-url={link}
          data-cts-object-extra-info="product"
          data-cts-id={link}
          data-cts-label="product"
          data-cts-name={title}
          data-cts-creative="affiliate-carousel"
        />
      )),
    [$price, $slide, $title, products],
  );

  return (
    <div className="cts-impression-group" data-cts-object-group="affiliate_gallery">
      <AffiliateGallery
        data-cts-object="affiliate-carousel"
        data-cts-object-name="affiliate-carousel"
        data-cts-id={title}
        data-cts-label="affiliate-carousel"
        data-cts-name="affiliate-carousel"
        data-cts-creative="affiliate-carousel"
        {...mergeOptions({ className: 'cts-impression-item' }, $base, props)}
      >
        <AffiliateGallery.Disclaimer.Line {...$line}>
          <AffiliateGallery.Disclaimer {...$disclaimer}>{disclaimer}</AffiliateGallery.Disclaimer>
        </AffiliateGallery.Disclaimer.Line>
        <SliderSwiper
          content={memoSlides}
          title={title}
          options={mergeOptions({ theme: componentTheme('swiper') }, $swiper)}
        />
      </AffiliateGallery>
    </div>
  );
};

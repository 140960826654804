import { useClassName } from '@/styles/JwVideoArticle';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { stripHtml } from 'lib/utils/stripHtml';
import NextImage from 'next/image';
import { useJWPlayer } from './JWPlayerContext';

export interface VideoArticleVideoContainerProps extends ComponentProps<'div'> {
  title?: string;
  thumbnail?: string;
  variant?: ClassNameProp<'horizontal' | 'vertical'>;
  mediaId?: string;
}

export const VideoArticleVideoContainerComponent: Component<VideoArticleVideoContainerProps> = ({
  mediaId,
  thumbnail,
  title,

  children,
  ...props
}) => {
  const { currentMedia } = useJWPlayer();
  const className = useClassName('videoContainer', props, { defaults: { variant: 'horizontal' } });

  let videoTitle = stripHtml(title);

  // Don't set title on initial video, as title prop can be customized
  // by editors in Labrador.
  if (currentMedia?.mediaid !== mediaId) {
    videoTitle = stripHtml(currentMedia?.title || title);
  }
  return (
    <>
      <div {...mergeProps({ className }, props)}>
        {thumbnail && <NextImage src={thumbnail} alt={videoTitle} fill unoptimized />}
        {children}
      </div>
    </>
  );
};

import { Tag, TagProps } from '@/components/Tag';
import { componentTheme } from '@/styles/TagsIndex';
import { StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export type TagsIndexTagProps = TagProps;

export const TagsIndexTagComponent: StandaloneComponent<TagsIndexTagProps> = (props) => {
  return (
    <Tag
      {...mergeProps(
        {
          options: {
            theme: componentTheme('tag'),
          },
        },
        props,
      )}
    />
  );
};

import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { A11y, Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide, SwiperSlideProps } from 'swiper/react';
import { BreakingNews, BreakingNewsProps } from './BreakingNews';
import { BreakingNewsItemProps } from './BreakingNews.Item';
import { BreakingNewsItemHeadlineProps } from './BreakingNews.Item.Headline';
import { BreakingNewsItemIconProps } from './BreakingNews.Item.Icon';
import { BreakingNewsItemLabelProps } from './BreakingNews.Item.Label';

import { Link, LinkProps } from '@/components/Link';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { BreakingNewsItemBlipProps } from './BreakingNews.Item.Blip';

export interface StandaloneBreakingNewsProps extends StandaloneComponentProps {
  articles?: {
    label?: React.ReactNode;
    headline?: React.ReactNode;
    link?: LinkProps;
  }[];
  options?: BreakingNewsProps & {
    $item?: BreakingNewsItemProps & {
      $blip?: BreakingNewsItemBlipProps;
      $headline?: BreakingNewsItemHeadlineProps;
      $label?: BreakingNewsItemLabelProps;
      $icon?: BreakingNewsItemIconProps['options'];
    };
    $swiper?: SwiperProps;
    $slide?: SwiperSlideProps;
  };
}

export const StandaloneBreakingNews: StandaloneComponent<StandaloneBreakingNewsProps> = ({
  articles,
  options,
  ...props
}) => {
  if (!articles?.length) {
    return null;
  }

  const { $item, $swiper, $slide, ...$base } = options ?? {};
  const {
    $blip: $itemBlip,
    $headline: $itemHeadline,
    $label: $itemLabel,
    $icon: $itemIcon,
    ...$baseItem
  } = $item ?? {};

  return (
    <BreakingNews {...$base} {...props}>
      <Swiper
        className="w-full"
        modules={[Navigation, Autoplay, A11y]}
        grabCursor
        autoplay
        loop
        speed={2500}
        {...$swiper}
      >
        {articles.map(({ label, headline, link }, index) => (
          <SwiperSlide key={index} {...$slide}>
            <Link
              data-cts-creative={`article-teaser-breaking:${headline}`}
              data-cts-path={link?.href}
              data-cts-label={`article-teaser-breaking:${headline}`}
              data-cts-name={link?.href}
              data-cts-id={link?.href?.split('/').at(-1)}
              content={
                <BreakingNews.Item {...$baseItem}>
                  <BreakingNews.Item.Blip {...$itemLabel} />
                  <BreakingNews.Item.Label {...$itemLabel}>{label}</BreakingNews.Item.Label>
                  <BreakingNews.Item.Headline {...$itemHeadline}>{headline}</BreakingNews.Item.Headline>
                  <BreakingNews.Item.Icon options={$itemIcon} />
                </BreakingNews.Item>
              }
              {...link}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </BreakingNews>
  );
};

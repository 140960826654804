import { componentClassName } from '@/styles/ArticleFooter';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { ArticleFooterTags } from './ArtcleFooter.Tags';
import { ArticleFooterLine } from './ArticleFooter.Line';

export type ArticleFooterProps = ComponentProps<'div'>;

const ArticleFooterComponent: Component<ArticleFooterProps> = ({ variant, colors, size, children, ...props }) => {
  const className = componentClassName('', { colors, size, variant });

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

export const ArticleFooter = Object.assign(ArticleFooterComponent, {
  Line: ArticleFooterLine,
  Tags: ArticleFooterTags,
});

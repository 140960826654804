/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneLoader as ResolvedStandaloneLoader } from 'base/components/standalone/Loader';

export const Loader = ResolvedStandaloneLoader;

export type LoaderProps = PropsFromComponent<typeof Loader>;

export const LoaderWith = (extras: DynamicStandaloneExtras): typeof Loader => {
    return function Loader(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Loader');
        return <ResolvedStandaloneLoader {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Twitter, TwitterProps } from './Twitter';

export interface StandaloneTwitterProps extends StandaloneComponentProps {
  markup?: TwitterProps['markup'];
  options?: Omit<TwitterProps, 'markup'>;
}

export const StandaloneTwitter: StandaloneComponent<StandaloneTwitterProps> = ({ markup, options, ...props }) => {
  return <Twitter {...{ markup }} {...options} {...props} />;
};

import { isProduction } from 'lib/labrador/utils';

export const JW_BASE_URL = 'https://cdn.jwplayer.com';
export const JW_THUMBNAILS_URL = `${JW_BASE_URL}/thumbs`;
export const JW_CDN_URL = `${JW_BASE_URL}/v2`;

export type brandType =
  | 'allas'
  | 'elle'
  | 'femina'
  | 'mabra'
  | 'residence'
  | 'recept'
  | 'baaam'
  | 'frida'
  | 'motherhood'
  | 'hant'
  | 'svenskdam';

export const videoProxyUrl = isProduction
  ? 'https://video-data.aller.se'
  : 'https://video-data-dev.aller.se';

export const B2B_USER_INFO = 'b2bVideoInfo';
export const JW_USER_ID = 'alAVThkH';

export enum VIDEO_ASPECT_RATIO {
  HORIZONTAL = '16:9',
  VERTICAL = '9:16',
}

import { componentClassName } from '@/styles/ArticleFooter';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type ArticleFooterLineProps = ComponentProps<'div'>;

export const ArticleFooterLine: Component<ArticleFooterLineProps> = ({ variant, size, colors, ...props }) => {
  const className = componentClassName('line', { colors, size, variant });

  return <div {...mergeProps({ className }, props)} />;
};

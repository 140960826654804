/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "description_colors_default": "",
  "description_size_default": "text-body-sm max-w-4xl",
  "description_variant_default": "",
  "group_colors_default": "",
  "group_size_default": "mt-3 gap-6",
  "group_variant_default": "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
  "headline_colors_default": "",
  "headline_size_default": "text-headline-lg sm:text-headline-2xl mt-2",
  "headline_variant_default": "",
  "item_icon_colors_default": "",
  "item_icon_size_default": "",
  "item_icon_variant_default": "transition-transform group-active:translate-x-1",
  "item_image_colors_default": "bg-white",
  "item_image_size_default": "w-16 h-16 data-[fill]:aspect-square",
  "item_image_variant_default": "rounded-full shrink-0",
  "item_title_colors_default": "",
  "item_title_size_default": "text-headline-xs",
  "item_title_variant_default": "grow",
  "item_colors_default": "bg-gray-100 hover:bg-gray-50 active:bg-gray-200",
  "item_size_default": "gap-x-3 p-3",
  "item_variant_default": "flex flex-row items-center transition-colors group rounded",
  "colors_default": "",
  "size_default": "mb-2 sm:mb-4 md:mb-6 mx-4",
  "variant_default": "flex flex-col gap-y-3"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "AuthorsList");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneFactBox as ResolvedStandaloneFactBox } from 'base/components/standalone/FactBox';

export const FactBox = ResolvedStandaloneFactBox;

export type FactBoxProps = PropsFromComponent<typeof FactBox>;

export const FactBoxWith = (extras: DynamicStandaloneExtras): typeof FactBox => {
    return function FactBox(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'FactBox');
        return <ResolvedStandaloneFactBox {...mergeProps({ options: { theme } }, props)} />;
    }
}
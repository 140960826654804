import { StandaloneComponentProps } from '@/types/component';
import { getSite } from 'lib/utils';
import { getBrandBy } from 'lib/utils/getBrandBy';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { useState } from 'react';
import { VideoArticle, VideoArticleProps } from './VideoArticle';
import { VideoArticleContainerProps } from './VideoArticle.Container';
import { VideoArticleExitProps } from './VideoArticle.Exit';
import { VideoArticleExitLinkProps } from './VideoArticle.Exit.Link';
import { VideoArticleExitMarketingTextProps } from './VideoArticle.Exit.MarketingText';
import { VideoArticleHeaderProps } from './VideoArticle.Header';
import { VideoArticleHeaderLinkProps } from './VideoArticle.Header.Link';
import { VideoArticleHeaderTitleProps } from './VideoArticle.Header.Title';
import { VideoArticleUnmuteProps } from './VideoArticle.Unmute';
import { VideoArticleUnmuteLabelProps } from './VideoArticle.Unmute.Label';
import { VideoArticleVideoContainerProps } from './VideoArticle.VideoContainer';
import { VideoArticleVideoContainerDurationProps } from './VideoArticle.VideoContainer.Duration';
import { VideoArticleVideoContainerNextVideoProps } from './VideoArticle.VideoContainer.NextVideo';
import { VideoArticleVideoContainerNextVideoDurationProps } from './VideoArticle.VideoContainer.NextVideo.Duration';
import { VideoArticleVideoContainerNextVideoTitleProps } from './VideoArticle.VideoContainer.NextVideo.Title';
import { VideoArticleDescriptionProps } from './VideoArticle.VideoDescription';
import { VERTICAL_ASPECT_RATIO } from './constants';
import { B2BVideoInfo, LabradorFieldsTextSize, VideoMetaData, VideoPlayerType } from './types/video';

export interface StandaloneJwVideoArticleProps extends StandaloneComponentProps {
  playerSrc?: string;
  videoData?: VideoPlayerType;
  videoPageLink?: string;
  durationMinutes?: string;
  disableLazyLoad?: boolean;
  showDescription?: boolean;
  titleTextSize?: LabradorFieldsTextSize;
  metadata?: VideoMetaData;
  verticalFloatingMode?: boolean;
  b2bVideo?: B2BVideoInfo;
  options?: VideoArticleProps & {
    $container?: VideoArticleContainerProps;
    $header?: VideoArticleHeaderProps & {
      $headerTitle?: VideoArticleHeaderTitleProps;
      $headerLink?: VideoArticleHeaderLinkProps;
    };
    $image: NextImageProps;
    $videoContainer?: VideoArticleVideoContainerProps;
    $duration?: VideoArticleVideoContainerDurationProps;
    $nextVideo?: VideoArticleVideoContainerNextVideoProps;
    $nextVideoDuration?: VideoArticleVideoContainerNextVideoDurationProps;
    $nextVideoTitle?: VideoArticleVideoContainerNextVideoTitleProps;
    $unmute?: VideoArticleUnmuteProps & {
      $label?: VideoArticleUnmuteLabelProps;
    };
    $exit?: VideoArticleExitProps & {
      $link?: VideoArticleExitLinkProps;
      $label?: VideoArticleExitMarketingTextProps;
    };
    $description?: VideoArticleDescriptionProps;
  };
}

export const StandaloneJwVideoArticle = (props: StandaloneJwVideoArticleProps) => {
  const {
    playerSrc,
    showDescription,
    videoPageLink,
    durationMinutes,
    titleTextSize,
    metadata,
    disableLazyLoad,
    videoData,
    verticalFloatingMode,
    b2bVideo,
    options,
  } = props;

  const {
    variant,
    colors,
    size,
    $container: containerProps,
    $header: headerProps,
    $videoContainer: videoContainerProps,
    $duration: durationProps,
    $nextVideo: nextVideoProps,
    $nextVideoDuration: nextVideoDurationProps,
    $nextVideoTitle: nextVideoTitleProps,
    $unmute: unmuteProps,
    $image: imageProps,
    $exit: exitProps,
    $description: descriptionProps,
    ...baseProps
  } = options ?? {};
  const [showNextVideoPopup, setShowNextVideoPopup] = useState(true);
  const { $label: unmuteLabelProps } = unmuteProps ?? {};
  const { $headerTitle: headerTitleProps, $headerLink: headerLinkProps } = headerProps ?? {};
  const { $link: exitLinkProps, $label: exitLabelProps } = exitProps ?? {};

  const siteId = getSite();
  const { slug } = getBrandBy({ slug: siteId }) ?? {};

  const orientation = videoData?.aspectRatio === VERTICAL_ASPECT_RATIO ? 'vertical' : 'horizontal';

  return (
    <VideoArticle playerSrc={playerSrc} size={size ?? orientation} {...{ variant, colors }} {...baseProps}>
      <VideoArticle.Container
        verticalFloatingMode={verticalFloatingMode}
        showNextVideoPopup={showNextVideoPopup}
        setShowNextVideoPopup={setShowNextVideoPopup}
        {...containerProps}
      >
        <VideoArticle.MetaData {...metadata} />
        {videoData?.title && (
          <VideoArticle.Header titleTextSize={titleTextSize} {...headerProps}>
            <VideoArticle.Header.Title mediaId={videoData?.videoId} {...headerTitleProps}>
              {videoData?.title}
            </VideoArticle.Header.Title>

            <VideoArticle.Header.Link
              videoPageLink={videoPageLink}
              mediaId={videoData?.videoId}
              ctsLabel="video-title-link"
              {...headerLinkProps}
            >
              <NextImage fill src={`/image/${slug}-video-logo.svg`} alt="Brand logo" {...imageProps} />
            </VideoArticle.Header.Link>
          </VideoArticle.Header>
        )}
        <VideoArticle.VideoContainer
          mediaId={videoData?.videoId}
          thumbnail={videoData?.thumbnail}
          title={videoData?.title}
          variant={orientation}
          {...videoContainerProps}
        >
          <VideoArticle.VideoContainer.Duration mediaId={videoData?.videoId} {...durationProps}>
            {durationMinutes}
          </VideoArticle.VideoContainer.Duration>
          <VideoArticle.VideoContainer.NextVideo {...nextVideoProps}>
            <VideoArticle.VideoContainer.NextVideo.Title mediaId={videoData?.videoId} {...nextVideoTitleProps}>
              {videoData?.title}
            </VideoArticle.VideoContainer.NextVideo.Title>
            <VideoArticle.VideoContainer.NextVideo.Duration mediaId={videoData?.videoId} {...nextVideoDurationProps}>
              {durationMinutes}
            </VideoArticle.VideoContainer.NextVideo.Duration>
          </VideoArticle.VideoContainer.NextVideo>
          {videoData && (
            <VideoArticle.JWPlayer
              {...videoData}
              verticalFloatingMode={verticalFloatingMode}
              disableLazyLoad={disableLazyLoad}
              videoPlacementPriority={videoData.videoPlacementPriority}
            />
          )}
          <VideoArticle.Unmute {...unmuteProps}>
            <VideoArticle.Unmute.Label {...unmuteLabelProps}>Slå på ljud</VideoArticle.Unmute.Label>
          </VideoArticle.Unmute>
          <VideoArticle.Exit
            setShowNextVideoPopup={setShowNextVideoPopup}
            mediaId={videoData?.videoId}
            labelProps={exitLabelProps}
            linkProps={exitLinkProps}
            b2bVideo={b2bVideo}
            {...exitProps}
          />
        </VideoArticle.VideoContainer>
        {showDescription && (
          <VideoArticle.Description mediaId={videoData?.videoId} {...descriptionProps}>
            {videoData?.description}
          </VideoArticle.Description>
        )}
      </VideoArticle.Container>
    </VideoArticle>
  );
};

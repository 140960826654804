import { SecondNativeArticleCampaign } from '@/types/secondNativeArticleCampaign';
import { http } from '@/utils/http';
import { getSite } from 'lib/utils';
import getUrl from 'lib/utils/getUrl';

export const getSecondNativeArticleCampaigns = async (
  articleCategory: string,
): Promise<SecondNativeArticleCampaign[]> => {
  const url = getUrl('api/native-article-campaigns');

  if (!url) {
    return [];
  }

  const brand = getSite();

  const response = await http.get<SecondNativeArticleCampaign[]>(url.href);
  const data = (response?.data ?? []).filter((item) => item.categories.includes(`${articleCategory}-${brand}`));
  return data;
};

import { Image, ImageProps } from '@/components/Image';
import c from '@/styles/InstagramArticlesList';
import { ClassNameProp, StandaloneComponent } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface InstagramArticlesListArticleImageProps extends Omit<ImageProps, 'src'> {
  imageId?: string;
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<'default'>;
}

export const InstagramArticlesListArticleImageComponent: StandaloneComponent<
  InstagramArticlesListArticleImageProps
> = ({ imageId, variant, colors, size, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('article_image_variant', variant, 'default');
  const colorClassName = getPropStyles('article_image_colors', colors, 'primary');
  const sizeClassName = getPropStyles('article_image_size', size, 'default');

  const imageWidth = props.options?.width ?? 320;
  const imageHeight = props.options?.height ?? 320;

  const componentClassName = cn(variantClassName, colorClassName, sizeClassName);
  const src = `${process.env.OCELOT_LABRADOR_IMAGE_SERVER}?imageId=${imageId}&width=${imageWidth}&height=${imageHeight}&compression=70`;

  return (
    <Image
      {...props}
      options={{
        ...props.options,
        $group: {
          ...props.options?.$group,
          className: componentClassName,
        },
      }}
      src={src}
    />
  );
};

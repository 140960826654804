/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneAffiliateItem as ResolvedStandaloneAffiliateItem } from 'base/components/standalone/AffiliateItem';

export const AffiliateItem = ResolvedStandaloneAffiliateItem;

export type AffiliateItemProps = PropsFromComponent<typeof AffiliateItem>;

export const AffiliateItemWith = (extras: DynamicStandaloneExtras): typeof AffiliateItem => {
    return function AffiliateItem(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'AffiliateItem');
        return <ResolvedStandaloneAffiliateItem {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { ArticleTeaserBodytext } from '@/components/ArticleTeaser/Bodytext';
import { LabradorComponent } from '@/types/component';

import { gridSystemBreakpoints } from 'base/configs/gridSystem';

export const SvenskdamLabradorArticleTeaserBodytext: LabradorComponent = ({ type, data, meta, descendants }) => {
  const imageUrl = descendants?.find((descendant) => descendant.type === 'labradorImage')?.data?.imageUrl ?? {};
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= gridSystemBreakpoints.sm;

  const { category, categoryUrl, title, publishedUrl, kicker, footer, options } = data ?? {};

  return (
    <ArticleTeaserBodytext
      links={{
        article: { href: publishedUrl },
        category: { href: categoryUrl || category },
      }}
      headline={kicker || 'LÄS MER PÅ SVENSK DAM'}
      description={title}
      category=""
      footer={footer}
      image={isMobile ? imageUrl.mobile : imageUrl.desktop}
      options={options}
      data-content-index={meta.contentIndex}
    />
  );
};

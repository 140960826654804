import c from '@/styles/RadioButton';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface RadioButtonInputProps extends Omit<ComponentProps<'input'>, 'size'> {
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export const RadioButtonInputComponent: Component<RadioButtonInputProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('input_colors', colors, 'default');
  const sizeClassName = getPropStyles('input_size', size, 'default');
  const variantClassName = getPropStyles('input_variant', variant, 'default');
  const componentClassName = cn('cursor-pointer', colorsClassName, sizeClassName, variantClassName, className);

  return <input className={componentClassName} {...props} />;
};

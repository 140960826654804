import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { TextSubtitle } from './TextSubtitle';

export interface StandaloneTextSubtitleProps extends StandaloneComponentProps<typeof TextSubtitle> {
  content: React.ReactNode;
}

export const StandaloneTextSubtitle: StandaloneComponent<StandaloneTextSubtitleProps> = ({ content, ...props }) => {
  return <TextSubtitle {...props}>{content}</TextSubtitle>;
};

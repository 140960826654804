import { propClassName } from '@/styles/NewsletterSignup';
import { cn } from 'lib/utils/cn';
import { createContext, useContext, useEffect } from 'react';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import type { ClassNameProp, Component, ComponentProps } from 'types/component';
import { NewsletterSignupButtonComponent } from './NewsletterSignup.Button';
import { NewsletterSignupDescriptionComponent } from './NewsletterSignup.Description';
import { NewsletterSignupFieldsetComponent } from './NewsletterSignup.Fieldset';
import { NewsletterSignupFieldsetCaptionComponent } from './NewsletterSignup.Fieldset.Caption';
import { NewsletterSignupFooterComponent } from './NewsletterSignup.Footer';
import { NewsletterSignupHeadlineComponent } from './NewsletterSignup.Headline';
import { NewsletterSignupInputCheckboxComponent } from './NewsletterSignup.InputCheckbox';
import { NewsletterSignupInputFieldComponent } from './NewsletterSignup.InputField';
import { NewsletterSignupModalComponent } from './NewsletterSignup.Modal';
import { NewsletterSignupModalContentComponent } from './NewsletterSignup.Modal.Content';
import { NewsletterSignupRootErrorComponent } from './NewsletterSignup.RootError';

export interface NewsletterSignupProps extends ComponentProps<'form'> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  onSubmitError?: SubmitErrorHandler<FieldValues>;
  onSubmitValid?: SubmitHandler<FieldValues>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export type NewsletterSignupState = ReturnType<typeof useForm>;

export const NewsletterSignupContext = createContext<NewsletterSignupState>({} as NewsletterSignupState);
export const NewsletterSignupProvider = NewsletterSignupContext.Provider;
export const useNewsletterSignupState = () => useContext(NewsletterSignupContext);

const NewsletterSignupComponent: Component<NewsletterSignupProps> = ({
  children,
  className,
  colors,
  onSubmit,
  onSubmitError,
  onSubmitValid,
  size,
  variant,
  ...props
}) => {
  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useNewsletterSignupState();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const emptyOnSubmitValid: SubmitHandler<FieldValues> = () => {};

  const onSubmitHandler: typeof onSubmit = (event) => {
    handleSubmit(onSubmitValid ?? emptyOnSubmitValid, onSubmitError)(event);
    onSubmit?.(event);
  };

  const colorClassName = propClassName('colors', colors, 'primary');
  const sizeClassName = propClassName('size', size, 'default');
  const variantClassName = propClassName('variant', variant, 'default');
  const componentClassName = cn(colorClassName, sizeClassName, variantClassName, className);

  return (
    <form className={componentClassName} onSubmit={onSubmitHandler} {...props}>
      {children}
    </form>
  );
};

export const NewsletterSignup = Object.assign(NewsletterSignupComponent, {
  Button: NewsletterSignupButtonComponent,
  Footer: NewsletterSignupFooterComponent,
  Description: NewsletterSignupDescriptionComponent,
  Fieldset: Object.assign(NewsletterSignupFieldsetComponent, {
    Caption: NewsletterSignupFieldsetCaptionComponent,
  }),
  Headline: NewsletterSignupHeadlineComponent,
  InputCheckbox: NewsletterSignupInputCheckboxComponent,
  InputField: NewsletterSignupInputFieldComponent,
  Modal: Object.assign(NewsletterSignupModalComponent, {
    Content: NewsletterSignupModalContentComponent,
  }),
  RootError: NewsletterSignupRootErrorComponent,
});

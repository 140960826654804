/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorRichTextBox as ResolvedLabradorRichTextBox } from 'base/components/labrador/RichTextBox';

export const LabradorRichTextBox: typeof ResolvedLabradorRichTextBox = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorRichTextBox {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorRichTextBoxProps = PropsFromComponent<typeof LabradorRichTextBox>;

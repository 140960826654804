import { kebabToCamel } from './kebabToCamel';

export const parseStyle = (styleString?: string) =>
  (styleString ?? '')
    .split(';')
    .filter((style) => style)
    .reduce((result, current) => {
      const [styleProp, value] = current.split(':');
      return { ...result, [kebabToCamel(styleProp)]: value.trim() };
    }, {});

export default parseStyle;

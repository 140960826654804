/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "content_colors_default": "",
  "content_size_default": "",
  "content_variant_default": "",
  "backgroundImage_colors_default": "",
  "backgroundImage_size_default": "",
  "backgroundImage_variant_default": "",
  "colors_default": "bg-slate-200",
  "size_default": "w-full sm:pe-40 sm:py-7 sm:pl-7 sm:gap-y-4 gap-y-2 pe-8 py-4 pl-4",
  "variant_default": "flex flex-col items-start not-prose relative z-1 overflow-hidden"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Banner");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;
/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { SvenskdamLabradorArticleTeaserDefault as ResolvedLabradorArticleTeaserDefault } from 'sites/svenskdam/components/labrador/ArticleTeaser/Default';

export const LabradorArticleTeaserDefault: typeof ResolvedLabradorArticleTeaserDefault = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorArticleTeaserDefault {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorArticleTeaserDefaultProps = PropsFromComponent<typeof LabradorArticleTeaserDefault>;

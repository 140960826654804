/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTwitter as ResolvedStandaloneTwitter } from 'base/components/standalone/Twitter';

export const Twitter = ResolvedStandaloneTwitter;

export type TwitterProps = PropsFromComponent<typeof Twitter>;

export const TwitterWith = (extras: DynamicStandaloneExtras): typeof Twitter => {
    return function Twitter(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Twitter');
        return <ResolvedStandaloneTwitter {...mergeProps({ options: { theme } }, props)} />;
    }
}
/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneMostRead as ResolvedStandaloneMostRead } from 'base/components/standalone/MostRead';

export const MostRead = ResolvedStandaloneMostRead;

export type MostReadProps = PropsFromComponent<typeof MostRead>;

export const MostReadWith = (extras: DynamicStandaloneExtras): typeof MostRead => {
    return function MostRead(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'MostRead');
        return <ResolvedStandaloneMostRead {...mergeProps({ options: { theme } }, props)} />;
    }
}
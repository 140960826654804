import { ArticleDisclosureLabel, ArticleDisclosureLabelProps } from '@/components/ArticleDisclosureLabel';
import { LoopAround, LoopAroundProps } from '@/components/LoopAround';
import { useAppState } from '@/hooks/useAppState';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import type { Content } from '@/types/content';
import { saveSeenArticle } from '@/utils/seenArticles';
import { mergeOptions, mergeProps } from 'lib/utils/merge';
import { renderBySelector } from 'modules/dynamic/components';
import { ReactNode } from 'react';
import { useEffectOnce } from 'react-use';
import { Article } from './Article';

type ExtendedArticleProps = {
  $articleDisclosureLabel?: ArticleDisclosureLabelProps['options'];
  $badge?: LoopAroundProps['options'];
};

export interface StandaloneArticleProps extends StandaloneComponentProps<typeof Article, ExtendedArticleProps> {
  article?: {
    before?: ReactNode;
    after?: ReactNode;
  };
  articleDisclosureLabels?: string[];
  badge?: LoopAroundProps;
  bodytext?: {
    before?: ReactNode;
    after?: ReactNode;
  };
  descendants?: Content[];
  hide?: {
    trendingMenu?: boolean;
    breakingNews?: boolean;
    badge?: boolean;
    recommendedArticles?: boolean;
  };
}

export const StandaloneArticle: StandaloneComponent<StandaloneArticleProps> = ({
  articleDisclosureLabels,
  badge,
  bodytext,
  descendants,
  hide,
  article,
  options,
  ...props
}) => {
  const [{ pageId }] = useAppState();

  useEffectOnce(() => {
    if (pageId) {
      saveSeenArticle(pageId);
    }
  });

  const { $bodytext, $recommended, $articleDisclosureLabel, $badge, ...$base } = options ?? {};

  return (
    <>
      {article?.before}

      <section>
        <ArticleDisclosureLabel content={articleDisclosureLabels?.join('<br>')} options={$articleDisclosureLabel} />

        {!hide?.badge && badge?.fragment && (
          <LoopAround
            {...mergeProps(
              {
                length: 40,
                options: mergeOptions(
                  {
                    className: 'w-screen relative left-1/2 -translate-x-1/2',
                  },
                  $badge,
                ),
              },
              badge,
              {
                fragment: <span>{badge.fragment}</span>,
              },
            )}
          />
        )}

        {!hide?.trendingMenu && renderBySelector('trendingMenu', descendants)}
        {!hide?.breakingNews && renderBySelector('breakingNews', descendants)}

        <Article {...{ descendants }} {...$base} {...props}>
          {bodytext?.before ?? renderBySelector('lab-dz-2', descendants)}
          <Article.Bodytext {...{ descendants }} {...$bodytext} />
          {bodytext?.after}
        </Article>
      </section>

      {article?.after}

      {!hide?.recommendedArticles && (
        <Article.Recommended affiliateGallery={renderBySelector('affiliateGallery', descendants)} {...$recommended} />
      )}
    </>
  );
};

import { useClassName } from '@/styles/Front';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Content } from '@/types/content';
import { mergeProps } from '@/utils/merge';
import { renderBySelector } from 'modules/dynamic/components';

export interface StandaloneFrontProps extends StandaloneComponentProps {
  descendants: Content[];
}

export const StandaloneFront: StandaloneComponent<StandaloneFrontProps> = ({ descendants, ...props }) => {
  const className = useClassName();

  return (
    <>
      {renderBySelector('trendingMenu', descendants)}
      {renderBySelector('breakingNews', descendants)}

      <section data-cts-scroll-depth-item="front" {...mergeProps({ className }, props)}>
        {renderBySelector('lab-dz-1', descendants)}
      </section>
    </>
  );
};

import { propClassName } from '@/styles/ArticleSlider';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { ArticleSliderDefaultSize, ArticleSliderSizes } from '.';

export interface ArticleSliderNavigationItemProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<ArticleSliderSizes>;
  variant?: ClassNameProp<'default'>;
}

export const ArticleSliderNavigationItemComponent: Component<ArticleSliderNavigationItemProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('navigation_item_colors', colors, 'primary');
  const sizeClassName = propClassName('navigation_item_size', size, ArticleSliderDefaultSize);
  const variantClassName = propClassName('navigation_item_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export type CountdownTime = {
  totalTime: number;
  durations: number[];
};

export const getCountdownTime = ({
  finishTime,
  setNegativeAsZero,
}: {
  finishTime?: string;
  setNegativeAsZero?: boolean;
}): CountdownTime => {
  const totalTime = finishTime ? Date.parse(finishTime) - Date.now() : 0;

  if (setNegativeAsZero && totalTime < 0) {
    return { totalTime: 0, durations: [0, 0, 0, 0] };
  }

  const days = Math.floor(totalTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((totalTime / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((totalTime / 1000 / 60) % 60);
  const seconds = Math.floor((totalTime / 1000) % 60);

  return { totalTime, durations: [days, hours, minutes, seconds] };
};

export default getCountdownTime;

import { useAppState } from '@/hooks/useAppState';
import { ClassNameProvider } from '@/styles/SaveArticleButton';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useSavedArticles } from 'lib/hooks/useSavedArticles';
import mergeProps, { mergeOptions } from 'lib/utils/mergeProps';
import { ReactNode, useRef } from 'react';
import { SaveArticleButton } from './SaveArticleButton';

export interface StandaloneSaveArticleButtonProps extends StandaloneComponentProps<typeof SaveArticleButton> {
  saveContent?: ReactNode;
  unsaveContent?: ReactNode;
  modalContent?: ReactNode;
}

export const StandaloneSaveArticleButton: StandaloneComponent<StandaloneSaveArticleButtonProps> = ({
  saveContent = 'Spara artikel',
  unsaveContent = 'Sparad artikel',
  modalContent,
  options,
  ...props
}) => {
  const [{ pageId }] = useAppState();
  const modalRef = useRef<HTMLDialogElement>(null);
  const [isArticleSaved, toggleSaveArticle] = useSavedArticles({
    articleId: String(pageId),
    notLoggedInCallback: () => modalRef.current?.showModal(),
  });

  const { $button, $modal, ...$base } = options ?? {};

  return (
    <ClassNameProvider value={$base}>
      <SaveArticleButton {...mergeProps($base, props)}>
        <SaveArticleButton.Button
          content={isArticleSaved ? unsaveContent : saveContent}
          options={mergeOptions({ onClick: toggleSaveArticle }, $button)}
        />
      </SaveArticleButton>

      {modalContent && (
        <SaveArticleButton.Modal ref={modalRef} onClick={() => modalRef.current?.close()} {...$modal}>
          {modalContent}
        </SaveArticleButton.Modal>
      )}
    </ClassNameProvider>
  );
};

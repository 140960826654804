/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorBlogSlider as ResolvedLabradorBlogSlider } from 'base/components/labrador/BlogSlider';

export const LabradorBlogSlider: typeof ResolvedLabradorBlogSlider = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorBlogSlider {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorBlogSliderProps = PropsFromComponent<typeof LabradorBlogSlider>;

import c from '@/styles/RadioButton';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { RadioButtonInputComponent } from './RadioButton.Input';
import { RadioButtonLabelComponent } from './RadioButton.Label';

export interface RadioButtonProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

const RadioButtonComponent: Component<RadioButtonProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('colors', colors, 'default');
  const sizeClassName = getPropStyles('size', size, 'default');
  const variantClassName = getPropStyles('variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const RadioButton = Object.assign(RadioButtonComponent, {
  Input: RadioButtonInputComponent,
  Label: RadioButtonLabelComponent,
});

import c from '@/styles/ArticleTeaserBodytext';
import type { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { ArticleTeaserBodytextVariant, DefaultArticleTeaserBodytextVariant } from './ArticleTeaserBodytext';

export interface ArticleTeaserBodytextFooterProps extends ComponentProps<'span'> {
  variant?: ClassNameProp<ArticleTeaserBodytextVariant>;
  colors?: ClassNameProp<ArticleTeaserBodytextVariant>;
  size?: ClassNameProp<'default'>;
}

export const ArticleTeaserBodytextFooter: Component<ArticleTeaserBodytextFooterProps> = ({
  className,
  colors,
  size,
  variant,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('footer_variant', variant, DefaultArticleTeaserBodytextVariant);
  const colorsClassName = getPropStyles('footer_colors', colors ?? variant, DefaultArticleTeaserBodytextVariant);
  const sizeClassName = getPropStyles('footer_size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <span className={componentClassName} {...props}>
      {children}
    </span>
  );
};

import { Image } from '@/components/Image';
import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ArticleTeaserBodytext, ArticleTeaserBodytextProps } from './ArticleTeaserBodytext';
import { ArticleTeaserBodytextCategoryProps } from './ArticleTeaserBodytext.Category';
import { ArticleTeaserBodytextDescriptionProps } from './ArticleTeaserBodytext.Description';
import { ArticleTeaserBodytextFooterProps } from './ArticleTeaserBodytext.Footer';
import { ArticleTeaserBodytextGroupProps } from './ArticleTeaserBodytext.Group';
import { ArticleTeaserBodytextHeadlineProps } from './ArticleTeaserBodytext.Headline';
import { ArticleTeaserBodytextImageProps } from './ArticleTeaserBodytext.Image';
import { ArticleTeaserBodytextLineProps } from './ArticleTeaserBodytext.Line';

export interface StandaloneArticleTeaserBodytextProps extends StandaloneComponentProps {
  links?: {
    article?: OptionalLinkProps;
    category?: OptionalLinkProps;
  };
  headline?: string;
  category?: string;
  description?: string;
  footer?: string;
  image: string;
  options?: ArticleTeaserBodytextProps & {
    $headline?: ArticleTeaserBodytextHeadlineProps;
    $category?: ArticleTeaserBodytextCategoryProps;
    $description?: ArticleTeaserBodytextDescriptionProps;
    $footer?: ArticleTeaserBodytextFooterProps;
    $line?: ArticleTeaserBodytextLineProps;
    $image?: ArticleTeaserBodytextImageProps;
    $group?: ArticleTeaserBodytextGroupProps;
  };
}

export const StandaloneArticleTeaserBodytext: StandaloneComponent<StandaloneArticleTeaserBodytextProps> = ({
  links,
  headline,
  category,
  description,
  footer,
  image,
  options,
  ...props
}) => {
  const {
    $headline: headlineProps,
    $category: categoryProps,
    $description: descriptionProps,
    $footer: footerProps,
    $line: lineProps,
    $image: imageProps,
    $group: groupProps,
    variant,
    ...baseProps
  } = options || {};

  return (
    <div>
      {headline && (
        <ArticleTeaserBodytext.Line variant={variant} {...lineProps}>
          <ArticleTeaserBodytext.Headline variant={variant} {...headlineProps}>
            {headline}
          </ArticleTeaserBodytext.Headline>
        </ArticleTeaserBodytext.Line>
      )}

      <ArticleTeaserBodytext variant={variant} {...baseProps} {...props}>
        <OptionalLink
          {...links?.article}
          content={
            <ArticleTeaserBodytext.Image {...imageProps}>
              <Image src={image} alt={category || ''} />
            </ArticleTeaserBodytext.Image>
          }
        />
        <ArticleTeaserBodytext.Group variant={variant} {...groupProps}>
          {category && (
            <OptionalLink
              {...links?.category}
              content={
                <ArticleTeaserBodytext.Category variant={variant} {...categoryProps}>
                  {category}
                </ArticleTeaserBodytext.Category>
              }
            />
          )}
          {description && (
            <OptionalLink
              {...links?.article}
              content={
                <ArticleTeaserBodytext.Description variant={variant} {...descriptionProps}>
                  {description}
                </ArticleTeaserBodytext.Description>
              }
            />
          )}
          {footer && (
            <ArticleTeaserBodytext.Footer variant={variant} {...footerProps}>
              {footer}
            </ArticleTeaserBodytext.Footer>
          )}
        </ArticleTeaserBodytext.Group>
      </ArticleTeaserBodytext>
    </div>
  );
};

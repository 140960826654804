import { propClassName } from '@/styles/AuthorsList';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils';

export interface AuthorsListGroupProps extends ComponentProps<'div'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const AuthorsListGroupComponent: Component<AuthorsListGroupProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('group_colors', colors);
  const sizeClassName = propClassName('group_size', size);
  const variantClassName = propClassName('group_variant', variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

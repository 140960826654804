import { checkForUserConsentAndGetAdConfig } from '../ads/userContentAdsConfig';
import { AD_SCHEDULE_ID } from '../constants';
import { floatBehavior, videoAdWatch } from '../features';
import { VideoPlayerType } from '../types/video';

export const setupPlayer = async (params: VideoPlayerType) => {
  const {
    videoId: mediaId,
    autostart,
    articleInfo,
    ypPartId = null,
    isNativeAd,
    verticalFloatingMode,
    hideAds,
  } = params;
  const player = window.jwplayer(document.getElementById(mediaId));
  const jwDefaults = window.jwDefaults;
  const customAdConfig = await checkForUserConsentAndGetAdConfig({
    autostart,
    articleInfo,
    ypPartId,
    isVerticalVideo: verticalFloatingMode,
  });

  const playerEnv = player.getEnvironment();
  const isMobile = playerEnv?.OS?.mobile;

  const features = [floatBehavior, videoAdWatch].map((setup) => setup(player, params, isMobile));

  const configOverrides = features.reduce((configValues, feature) => {
    return {
      ...configValues,
      setTimeEvents: true, //for JW 3s, 10s, 15 sec analytics
      ...feature.preConfig(),
    };
  }, {});

  const isAutoStart = autostart === 'viewable';
  const adScheduleType = isAutoStart ? AD_SCHEDULE_ID.ATP : AD_SCHEDULE_ID.CTP;
  const brand = process.env.OCELOT_SITE as keyof typeof adScheduleType;

  const videoPlayerConfig = {
    ...params,
    aspectratio: params.aspectRatio,
    advertising: {
      ...jwDefaults.advertising,
      ...customAdConfig,
      adscheduleid: adScheduleType[brand],
    },
    autoPause: {
      viewability: true,
      pauseAds: true,
    },
    ...configOverrides,
  };

  if ((customAdConfig as any).noConsent || hideAds) {
    //Show no ads when consent is not given
    videoPlayerConfig.advertising = {};
  }

  player.setup(videoPlayerConfig).on('ready', (/*{setupTime: any}*/) => {
    features.forEach((feature) => feature.onReady());
  });

  //Add cts
  window.cts = window.cts || {};
  window.cts.jwplayer = window.cts.jwplayer || [];
  window.cts.jwplayer.push({ ...player });

  return player;
};

/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandalonePre as ResolvedStandalonePre } from 'base/components/standalone/Pre';

export const Pre = ResolvedStandalonePre;

export type PreProps = PropsFromComponent<typeof Pre>;

export const PreWith = (extras: DynamicStandaloneExtras): typeof Pre => {
    return function Pre(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Pre');
        return <ResolvedStandalonePre {...mergeProps({ options: { theme } }, props)} />;
    }
}
import { propClassName } from '@/styles/ScrollPageControls';
import type { ClassNameProp, Component, ComponentProps } from '@/types/component';
import cn from 'lib/utils/cn';
import { ScrollPageControlsButtonComponent } from './ScrollPageControls.Button';
import { ScrollPageControlsLoaderComponent } from './ScrollPageControls.Loader';

export interface ScrollPageControlsProps extends ComponentProps<'section'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

const ScrollPageControlsComponent: Component<ScrollPageControlsProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('colors', colors);
  const sizeClassName = propClassName('size', size);
  const variantClassName = propClassName('variant', variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <section className={componentClassName} {...props}>
      {children}
    </section>
  );
};

export const ScrollPageControls = Object.assign(ScrollPageControlsComponent, {
  Button: ScrollPageControlsButtonComponent,
  Loader: ScrollPageControlsLoaderComponent,
});

/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { LabradorArticleSlider as ResolvedLabradorArticleSlider } from 'base/components/labrador/ArticleSlider';

export const LabradorArticleSlider: typeof ResolvedLabradorArticleSlider = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorArticleSlider {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorArticleSliderProps = PropsFromComponent<typeof LabradorArticleSlider>;

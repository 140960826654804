import logger from 'lib/utils/logger';
import { useEffect } from 'react';

export type UseIntersectionObserver = (
  refs: React.RefObject<any>[],
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit
) => void;

export const useIntersectionObserver: UseIntersectionObserver = (refs, callback, options) => {
  useEffect(() => {
    const elements = refs.map((ref) => ref.current).filter(Boolean);
    const observer = new IntersectionObserver(callback, options);

    elements.forEach((element) => {
      try {
        if (element) {
          observer?.observe(element);
        }
      } catch (error) {
        logger.debug('Failed to observe an element', { error, element });
      }
    });

    return () => {
      elements.forEach((element) => {
        try {
          if (element) {
            observer?.unobserve(element);
          }
        } catch (error) {
          logger.debug('Failed to unobserve an element', { error, element });
        }
      });
    };
  }, [callback, options, refs]);
};

import { propClassName } from '@/styles/SavedArticles';
import { cn } from 'lib/utils/cn';
import type { ClassNameProp, Component, ComponentProps } from 'types/component';
import { SavedArticlesArticleComponent } from './SavedArticles.Article';
import { SavedArticlesArticleCaptionComponent } from './SavedArticles.Article.Caption';
import { SavedArticlesArticleDescriptionComponent } from './SavedArticles.Article.Description';
import { SavedArticlesArticleHeaderComponent } from './SavedArticles.Article.Header';
import { SavedArticlesArticleImageComponent } from './SavedArticles.Article.Image';
import { SavedArticlesArticleLinkComponent } from './SavedArticles.Article.Link';
import { SavedArticlesButtonComponent } from './SavedArticles.Button';
import { SavedArticlesEmptyComponent } from './SavedArticles.Empty';
import { SavedArticlesHeadlineComponent } from './SavedArticles.Headline';
import { SavedArticlesIconComponent } from './SavedArticles.Icon';
import { SavedArticlesLineComponent } from './SavedArticles.Line';
import { SavedArticlesTagComponent } from './SavedArticles.Tag';

export interface SavedArticlesProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

const SavedArticlesComponent: Component<SavedArticlesProps> = ({
  className,
  variant,
  colors,
  size,
  children,
  ...props
}) => {
  const variantClassName = propClassName('variant', variant, 'default');
  const colorsClassName = propClassName('colors', colors, 'default');
  const sizeClassName = propClassName('size', size, 'default');
  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const SavedArticles = Object.assign(SavedArticlesComponent, {
  Headline: SavedArticlesHeadlineComponent,
  Line: SavedArticlesLineComponent,
  Button: SavedArticlesButtonComponent,
  Tag: SavedArticlesTagComponent,
  Icon: SavedArticlesIconComponent,
  Empty: SavedArticlesEmptyComponent,
  Article: Object.assign(SavedArticlesArticleComponent, {
    Header: SavedArticlesArticleHeaderComponent,
    Caption: SavedArticlesArticleCaptionComponent,
    Description: SavedArticlesArticleDescriptionComponent,
    Link: SavedArticlesArticleLinkComponent,
    Image: SavedArticlesArticleImageComponent,
  }),
});

import { OptionalLink } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Byline, BylineProps } from './Byline';
import { BylineAuthorProps } from './Byline.Author';
import { BylineImageProps } from './Byline.Image';
import { BylineTextProps } from './Byline.Text';

export enum BYLINE_TYPE {
  AUTHOR = 'author',
  PHOTOGRAPHER = 'photographer',
  FREE = 'free',
}

export interface StandaloneBylineProps extends StandaloneComponentProps {
  type?: BYLINE_TYPE;
  author: ReactNode;
  authorPageSlug?: string;
  image?: BylineImageProps['src'];
  text?: ReactNode;
  options?: BylineProps & {
    $image?: BylineImageProps;
    $author?: BylineAuthorProps;
    $text?: BylineTextProps;
  };
}

export const StandaloneByline: StandaloneComponent<StandaloneBylineProps> = ({
  type = BYLINE_TYPE.AUTHOR,
  author,
  authorPageSlug,
  image,
  text,
  options,
  ...props
}) => {
  const { $image: imageProps, $author: authorProps, $text: textProps, ...baseProps } = options ?? {};

  const BylineText = (bylineText: ReactNode) =>
    bylineText ? <Byline.Text {...textProps}>{bylineText}</Byline.Text> : null;

  return (
    <OptionalLink
      href={authorPageSlug && `/vara-skribenter/${authorPageSlug}`}
      content={
        <Byline {...baseProps} {...props}>
          {type === BYLINE_TYPE.AUTHOR &&
            (image ? <Byline.Image src={image} alt="" {...imageProps} /> : BylineText('Av'))}
          {type === BYLINE_TYPE.PHOTOGRAPHER && BylineText('Fotograf')}
          {type === BYLINE_TYPE.FREE && BylineText(text)}
          <Byline.Author {...authorProps}>{author}</Byline.Author>
        </Byline>
      }
    />
  );
};

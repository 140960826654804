import { ImageProps } from '@/components/Image';
import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { ClassNameProvider } from '@/styles/AffiliateItem';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import React, { Fragment, ReactNode } from 'react';
import { AffiliateItem } from './AffiliateItem';

export type AffiliateProductVariants = 'default' | 'alternative';

export interface AffiliateItemProduct {
  headline?: ReactNode;
  image?: ImageProps;
  links?: {
    link?: OptionalLinkProps;
    caption?: ReactNode;
    affiliate?: ReactNode;
  }[];
  vendor?: ReactNode;
}

export interface StandaloneAffiliateItemProps extends StandaloneComponentProps<typeof AffiliateItem> {
  counter?: ReactNode;
  headline?: ReactNode;
  description?: ReactNode;
  subtitle?: ReactNode;
  products?: AffiliateItemProduct[];
}

export const StandaloneAffiliateItem: StandaloneComponent<StandaloneAffiliateItemProps> = ({
  counter,
  headline,
  description,
  subtitle,
  products,
  options,
  ...props
}) => {
  const { $counter, $description, $group, $subtitle, $headline, $product: productProps, ...$base } = options ?? {};

  const {
    $image: $productImage,
    $headline: $productHeadline,
    $caption: $productCaption,
    $vendor: $productVendor,
    ...$product
  } = productProps ?? {};

  const inferredVariant: AffiliateProductVariants = products?.length === 1 ? 'default' : 'alternative';
  $base.variant = inferredVariant;

  return (
    <ClassNameProvider value={$base} options={{ fallbacks: { size: 'variant' } }}>
      <AffiliateItem {...$base} {...props}>
        <AffiliateItem.Group {...$group}>
          {counter && <AffiliateItem.Counter {...$counter}>{counter}</AffiliateItem.Counter>}
          {headline && <AffiliateItem.Headline {...$headline}>{headline}</AffiliateItem.Headline>}
          {subtitle && <AffiliateItem.Subtitle {...$subtitle}>{subtitle}</AffiliateItem.Subtitle>}
          {description && <AffiliateItem.Description {...$description}>{description}</AffiliateItem.Description>}
        </AffiliateItem.Group>

        {products?.map((product, index) => (
          <Fragment key={index}>
            <AffiliateItem.Product.Image {...mergeProps({ options: $productImage }, product.image)} />

            <AffiliateItem.Product {...$product}>
              <AffiliateItem.Product.Headline {...$productHeadline}>{product.headline}</AffiliateItem.Product.Headline>

              {product.links?.map((productLink, index) => (
                <React.Fragment key={index}>
                  {productLink.affiliate && (
                    <AffiliateItem.Product.Vendor {...$productVendor}>
                      {productLink.affiliate}
                    </AffiliateItem.Product.Vendor>
                  )}
                  <OptionalLink
                    target="_blank"
                    {...productLink.link}
                    content={
                      <AffiliateItem.Product.Caption {...$productCaption}>
                        {productLink.caption}
                      </AffiliateItem.Product.Caption>
                    }
                  />
                </React.Fragment>
              ))}

              <AffiliateItem.Product.Vendor {...$productVendor}>{product.vendor}</AffiliateItem.Product.Vendor>
            </AffiliateItem.Product>
          </Fragment>
        ))}
      </AffiliateItem>
    </ClassNameProvider>
  );
};
